import { applyMiddleware, configureStore, Tuple } from '@reduxjs/toolkit';
import { vacanciesReducer } from './slices/vacancies';
import { partnersReducer } from './slices/partners';
import { usersReducer } from './slices/users';
import { permissionsReducer } from './slices/permissions';
import { rolesReducer } from './slices/roles';
import { stacksReducer } from './slices/stacks';
import { statusesReducer } from './slices/statuses';
import { rateTypesReducer } from './slices/rateTypes';
import { partnerTypesReducer } from './slices/partnerTypes';
import { processingsReducer } from './slices/processings';
import { recruitmentsReducer } from './slices/recruitments';
import { scoringsReducer } from './slices/scorings';
import { scoringAnswersReducer } from './slices/scoringAnswers';
import { scoringCriteriasReducer } from './slices/scoringCriteria';

import { thunk } from 'redux-thunk';
import { partnerContactsReducer } from './slices/partnerContacts';
import { partnerDocumentsReducer } from './slices/partnerDocuments';
import { partnerRequisitesReducer } from './slices/partnerRequisites';

const reducer = {
    vacancies: vacanciesReducer,
    partners: partnersReducer,
    users: usersReducer,
    permissions: permissionsReducer,
    roles: rolesReducer,
    stacks: stacksReducer,
    statuses: statusesReducer,
    rateTypes: rateTypesReducer,
    partnerTypes: partnerTypesReducer,
    processings: processingsReducer,
    recruitments: recruitmentsReducer,
    scorings: scoringsReducer,
    scoringAnswers: scoringAnswersReducer,
    scoringCriterias: scoringCriteriasReducer,
    partnerContacts: partnerContactsReducer,
    partnerDocuments: partnerDocumentsReducer,
    partnerRequisites: partnerRequisitesReducer,
};

const logger = store => next => action => {
    console.group(action.type)
    console.info('dispatching', action)
    let result = next(action)
    console.log('next state', store.getState())
    console.groupEnd()
    return result
  }

export default configureStore({
    reducer: reducer,
    devTools: true,
    middleware: () => new Tuple(thunk, logger)
})
