import LoginForm from "../components/LoginForm";

const LoginPage = () => {
    return(
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img class="w-16 h-16 mr-2" src="https://static.tildacdn.com/tild3062-3231-4466-b039-616335656565/logo_color_transpare.svg" alt="logo" />
                .Core    
            </a>
            <main class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <LoginForm/>      
                </div>
                  
            </main>    
        </div>
        
        
    )
};

export default LoginPage;