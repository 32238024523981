import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { Button, Label, TextInput } from 'flowbite-react';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const auth = useAuth();
  const history = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email, password };

    try {
      await auth.login(credentials);
      // Redirect to desired page after successful login
      history.push('/dashboard');
    } catch (error) {
      setErrorMessage('Login failed. Please try again.');
    }
  };

  return (
    <div className="login-form">
      <form onSubmit={handleSubmit} class="flex max-w-md flex-col gap-4">
        {/* <div>
          <Label htmlFor="email" value="Ваша почта">Email</Label>
          <TextInput type="email" name="email" id="email" placeholder="name@aiotex.ru" value={email} onChange={handleChange} required />
        </div>
        <div>
          <Label>Пароль</Label>
          <TextInput type="password" name="password" value={password} onChange={handleChange} required />
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <Button type="submit">Войти</Button> */}
        <Button onClick={() => auth.login()}>Aiotex SSO</Button>
      </form>
    </div>
  );
};

export default LoginForm;
