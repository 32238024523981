import { connect, useDispatch } from "react-redux";
import { useContext, useState, useEffect } from "react";
import withRouter from "../context/RouterContext";
import Layout from "../components/layout";
import {
    Breadcrumb,
    Label,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextInput,
    Drawer,
    Badge,
    Checkbox,
} from "flowbite-react";
import {
    HiCheckCircle,
    HiDownload,
    HiHome,
    HiLink,
    HiMail,
    HiPencil,
    HiPhone,
    HiPlus,
    HiPlusCircle,
    HiSave,
    HiTrash,
    HiUpload,
    HiX,
} from "react-icons/hi";
import { AuthContext } from "../context/AuthContext";
import {
    usePartnerDetailActions,
    useFetchPartnerDetail,
    useFetchStatuses,
    usePartnerActions,
    useFetchPartnerTypes,
    fetchPartners,
    useFetchPartners,
} from "../hooks/useApi";
import { Box, IconButton, Input, Link, Button, List, ListItem, ListItemContent, Typography } from "@mui/joy";
import { useLoading } from "../context/LoadingContext";
import { Form, useSearchParams } from "react-router-dom";
import { EditableBadgeField, EditableLinkField, EditableMultiLinkField, EditableTextField } from "../components/editableField";
import GenericTable from "../components/tableAdmin";
import { processingsActions } from "../slices/processings";
import { partnersActions } from "../slices/partners";
import { statusesActions } from "../slices/statuses";
import { partnerTypesActions } from "../slices/partnerTypes";
import { partnerDocumentsActions } from "../slices/partnerDocuments";
import { partnerRequisitesActions } from "../slices/partnerRequisites";
import { partnerContactsActions } from "../slices/partnerContacts";
import { vacanciesActions } from "../slices/vacancies";

const PartnerPage = (props) => {
    const dispatch = useDispatch();
    const [updateTrigger, setUpdateTrigger] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id');
    const view = searchParams.get('view');

    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedContactName, setSelectedContactName] = useState("");
    const [selectedContactEmail, setSelectedContactEmail] = useState("");
    const [selectedContactPhone, setSelectedContactPhone] = useState("");

    const [newDoc, setNewDoc] = useState({name: ""});

    const [docModalOpen, setDocModalOpen] = useState(false);

    const handleUpdatePartner = (query, field) => {
        dispatch(props.partnersActions.update({id: props.partners.entity.id, data: {[field]: query ? query : ""}}));
    }

    const handleUpdateTypes = (query) => {
        dispatch(props.partnersActions.update({id: props.partners.entity.id, data: {types: query ? query.map(q => q.id) : []}}));
    }

    const handleSearchTypes = (query) => {
        return props.partnerTypes.entities.filter((type) => type.name.toLowerCase().includes(query.toLowerCase()))
    }

    const handleEditRequisit = (reqId, reqAttr, reqVal) => {
        if (reqId) {
            dispatch(props.partnerRequisitesActions.update({id: reqId, data: {[reqAttr]: reqVal}}))
        } else {
            dispatch(props.partnerRequisitesActions.create({data: {[reqAttr]: reqVal}}))
        }
    }

    const saveContactData = async () => {
        if (selectedContactId) {
            if (!selectedContactName || selectedContactName === "") {
                window.alert("Наименование контакта обязательно");
                return;
            }
            if (
                !(!!selectedContactEmail || !!selectedContactPhone)
            ) {
                window.alert("Укажите email или телефон контакта");
                return;
            }
            try {
                dispatch(props.partnerContactsActions.update({id: selectedContactId, data: {
                    name: selectedContactName,
                    email: selectedContactEmail,
                    phone: selectedContactPhone,
                }}));
                dispatch(props.partnersActions.getAll())
                setContactModalOpen(false);
                setSelectedContactId(null);
                setSelectedContactName("");
                setSelectedContactEmail("");
                setSelectedContactPhone("");
            } catch (error) {
                window.alert("Failed to fetch partners data", error);
                // Handle error: show error message, etc.
            }
        } else {
            if (!selectedContactName || selectedContactName === "") {
                window.alert("Наименование контакта обязательно");
                return;
            }
            if (
                !selectedContactEmail ||
                selectedContactEmail === "" ||
                !selectedContactPhone ||
                selectedContactPhone === ""
            ) {
                window.alert("Укажите email или телефон контакта");
                return;
            }
            try {
                dispatch(props.partnerContactsActions.create({date: {
                    partner_id: id,
                    name: selectedContactName,
                    email: selectedContactEmail,
                    phone: selectedContactPhone,
                }}))
                dispatch(props.partnersActions.getAll())
                setContactModalOpen(false);
                setSelectedContactId(null);
                setSelectedContactName("");
                setSelectedContactEmail("");
                setSelectedContactPhone("");
            } catch (error) {
                console.error("Failed to fetch partners data", error);
                // Handle error: show error message, etc.
            }
        }
    };

    const handleDeleteContact = async (contactId) => {
        if (
            contactId &&
            window.confirm("Точно нужно удалить контакт?")
        ) {
            dispatch(props.partnerContactsActions.drop({id: contactId}))
            dispatch(props.partnersActions.updateState({target: "entity", data: {
                ...props.partners.entity,
                contacts: props.partners.entity.contacts.filter(c => c.id != contactId)
            }}))
        }
    };

    const handleEditContact = (contact) => {
        setSelectedContactId(
            contact?.id,
        );
        setSelectedContactName(
            contact?.name,
        );
        setSelectedContactEmail(
            contact?.email,
        );
        setSelectedContactPhone(
            contact?.phone,
        );
        setContactModalOpen(true);
    }

    const uploadDocument = () => {
        if (newDoc.url) {
            dispatch(props.partnerDocumentsActions.create({data: {
                partner_id: parseInt(id),
                name: newDoc.name,
                file_path: newDoc.url
            }}))
        } else {
            const formData = new FormData();
            formData.append('partner_id', parseInt(id));
            formData.append('name', newDoc.name);
            formData.append('file_path', newDoc.file);
            dispatch(props.partnerDocumentsActions.create({
                data: formData, 
                additionalEndpoint: "upload", 
                headers: {'content-type': 'multipart/form-data'}
            }))
        }
    }

    const handeUpdateChild = async (childs) => {
        for (const child of childs) {
            if (!props.partners.entities.filter(p => p.parent_id===props.partners.entity.id).includes(child)) {
                dispatch(props.partnersActions.update({id: child.id, data: {parent_id: props.partners.entity.id}}));
            };
        }
        
        for (const partner of props.partners.entities.filter(p => p.parent_id === props.partners.entity.id)) {
            if (!childs.includes(partner)) {
                dispatch(props.partnersActions.update({id: partner.id, data: {parent_id: null}}));
            };
        }
    }

    useEffect(()=>{
        console.warn("fetch partner detail", props)
        if (view === "partner" && id) {
            if ((!props.processings.entities || props.processings.entities.length === 0) && !props.processings.loading) dispatch(props.processingsActions.getAll());
            if (id != "new") {
                if ((!props.partnerRequisites.entities || props.partnerRequisites.entities.length === 0 || props.partnerRequisites.lastUpdated === 'filtred') && !props.partnerRequisites.loading) dispatch(props.partnerRequisitesActions.getFiltred({partner_id: parseInt(id)}));
                if ((!props.partnerContacts.entities || props.partnerContacts.entities.length === 0 || props.partnerContacts.lastUpdated === 'filtred') && !props.partnerContacts.loading) dispatch(props.partnerContactsActions.getFiltred({partner_id: parseInt(id)}));
                if ((!props.partnerDocuments.entities || props.partnerDocuments.entities.length === 0 || props.partnerDocuments.lastUpdated === 'filtred') && !props.partnerDocuments.loading) dispatch(props.partnerDocumentsActions.getFiltred({partner_id: parseInt(id)}));
                if (!props.partners.entity || props.partners.entity?.id != parseInt(id)) dispatch(props.partnersActions.get({id: id}));
            }
        }
    }, [id])

    return (
        props.partners.entity && <Drawer
        open={view === "partner" && id ? true : false}
        onClose={() => {setSearchParams(); dispatch(props.partnersActions.updateState({target: "entity", data: null}))}}
        position="right"
        className="w-11/12 md:w-2/3 mt-[var(--Header-height)] md:mt-0"
        style={{
          marginTop: {
            xs: "var(--Header-height)",
            sm: 0,
          },
        }}
      >
            <Modal
                id="contactEdit"
                onClose={() => setContactModalOpen(false)}
                show={contactModalOpen}
            >
                <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                    <strong>Менеджер контактов</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 items-end">
                        <div>
                            <Label htmlFor="name">Наименование</Label>
                            <div className="mt-1">
                                <TextInput
                                    required
                                    id="name"
                                    name="name"
                                    placeholder="Зубенко Михаил Петрович"
                                    value={selectedContactName}
                                    onChange={(v) =>
                                        setSelectedContactName(v.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="email">Email</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="email"
                                    name="email"
                                    placeholder="mafioznik@aiotex.ru"
                                    type="email"
                                    value={selectedContactEmail}
                                    onChange={(v) =>
                                        setSelectedContactEmail(v.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="phone">Тел.</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="phone"
                                    name="phone"
                                    type="phone"
                                    value={selectedContactPhone}
                                    onChange={(v) =>
                                        setSelectedContactPhone(v.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <Button
                                color="gray"
                                onClick={() => {
                                    saveContactData();
                                }}
                            >
                                <div className="flex items-center gap-x-3">
                                    <HiPlus className="text-xl" />
                                    <span>Сохранить</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="grid grid-cols-4 justify-items-stretch place-items-center">
                <Typography className="col-span-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                    {props.partners.entity && <EditableTextField
                    value={props.partners.entity.name}
                    onApply={(v) => {
                        handleUpdatePartner(v, "title");
                    }}
                    />}
                </Typography>
                <EditableBadgeField 
                    value={props.partners.entity?.status?.id}
                    badges={props.statuses.entities.filter(status => status.relation === "partner")}
                    onApply={(v) => {
                        handleUpdatePartner(v, "status");
                        
                        
                    }}
                />
            </div>
            <div className="pt-8">
                <div className="rounded-lg bg-white p-4 shadow sm:p-6 xl:p-8 space-y-2">
                    <div>
                        <h6 className="text-lg text-gray-900">Атрибуты контрагента</h6>
                        <List>
                            <ListItem>
                                <ListItemContent>
                                    <EditableMultiLinkField 
                                        value={props.partners.entity.types}
                                        onSearch={(v) => handleSearchTypes(v)}
                                        onApply={(v) => handleUpdateTypes(v)}
                                        labelComponent={
                                            <Typography
                                              level="body-sm"
                                              gutterBottom
                                              className="text-gray-500"
                                            >
                                              Тип контрагента
                                            </Typography>
                                          }
                                    />
                                </ListItemContent>
                            </ListItem>
                            <ListItem>
                                <ListItemContent>
                                    <Box className="w-full grid grid-cols-5 items-baseline">
                                        <Box className="col-span-2 md:col-span-1">
                                            <Typography
                                                    level="body-sm"
                                                    gutterBottom
                                                    className="text-gray-500"
                                                    >
                                                    Является группой
                                            </Typography>
                                        </Box>
                                        <Box className="col-span-3 md:col-span-4 px-4">
                                            <Checkbox checked={props.partners.entity.is_group} onChange={(v) => handleUpdatePartner(v.target.checked, "is_group")} />
                                        </Box>
                                    </Box>
                                </ListItemContent>
                            </ListItem>
                            <ListItem>
                                <ListItemContent>
                                    <Typography
                                        level="body-sm"
                                        gutterBottom
                                        className="flex text-gray-500 align-middle"
                                    >
                                        Контакты контрагента
                                    </Typography>
                                    <GenericTable
                                        data={props.partners.entity.contacts ? props.partners.entity.contacts : []}
                                        columns={[
                                            {name: "name", alias: "Имя"},
                                            {name: "email", alias: "Почта"},
                                            {name: "phone", alias: "Телефон"}
                                        ]}
                                        onDelete={handleDeleteContact}
                                        onEdit={handleEditContact}
                                        onClick={(v)=>console.log(v)}
                                    />        
                                    <IconButton size="sm" onClick={() => handleEditContact()}>
                                        <HiPlus />
                                    </IconButton>                        
                                </ListItemContent>
                            </ListItem>
                            <ListItem>
                                <ListItemContent>
                                    <Typography
                                        level="body-sm"
                                        gutterBottom
                                        className="flex text-gray-500 align-middle"
                                    >
                                        Реквизиты контрагента
                                    </Typography>
                                    <GenericTable
                                        data={props.partnerRequisites.entities ? props.partnerRequisites.entities.filter(r => r.partner_id === parseInt(id)) : []}
                                        columns={[
                                            {name: "name", alias: "Реквизит", 
                                                mod: (obj_id, value) => <EditableTextField 
                                                    value={value}
                                                    onApply={(v) => handleEditRequisit(obj_id, "name", v)}
                                                />
                                            },
                                            {name: "value", alias: "Значение",  mod: (obj_id, value) => <EditableTextField 
                                                value={value}
                                                onApply={(v) => handleEditRequisit(obj_id, "value", v)}
                                            />},
                                        ]}
                                        onDelete={(v)=>{
                                            dispatch(props.partnerRequisitesActions.drop({id: v}))}}
                                    />        
                                    <IconButton size="sm" onClick={() => {
                                        dispatch(props.partnerRequisitesActions.create({data: {partner_id: props.partners.entity.id, name: "Реквизит", value: "Значение"}}))}}>
                                        <HiPlus />
                                    </IconButton>                        
                                </ListItemContent>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </div>
            <div className="pt-8">
                <div className="rounded-lg bg-white p-4 shadow sm:p-6 xl:p-8 space-y-2">
                    <div>
                        <h6 className="text-lg text-gray-900">Связанные контрагенты</h6>
                        <List>
                            <ListItem>
                                <ListItemContent>
                                    <EditableLinkField 
                                        value={props.partners.entities.filter(p => p.id === props.partners.entity.parent_id)?.pop()}
                                        onSearch={(v) => props.partners.entities.filter(p => p.is_group && p.name.toLowerCase().includes(v.toLowerCase()))}
                                        onApply={(v) => handleUpdatePartner(v?.id, "parent_id")}
                                        labelComponent={<Typography
                                            level="body-sm"
                                            gutterBottom
                                            className="flex text-gray-500 align-middle"
                                        >
                                            Головной контрагент
                                        </Typography>}
                                    />
                                </ListItemContent>
                            </ListItem>
                            {props.partners.entity.is_group && <ListItem>
                                <ListItemContent>
                                    <Typography
                                        level="body-sm"
                                        gutterBottom
                                        className="flex text-gray-500 align-middle"
                                    >
                                        Дочерние контрагенты
                                    </Typography>
                                    <EditableMultiLinkField 
                                        value={props.partners.entities.filter(p => p.parent_id === props.partners.entity.id)}
                                        onSearch={(v) => props.partners.entities.filter(p => p.name.toLowerCase().includes(v.toLowerCase()))}
                                        onApply={(v) => handeUpdateChild(v)}
                                    />
                                </ListItemContent>
                            </ListItem>}
                        </List>                      
                    </div>
                </div>
            </div>
            <div className="pt-8">
                <div className="rounded-lg bg-white p-4 shadow sm:p-6 xl:p-8 space-y-2">
                    <div>
                        <h6 className="text-lg text-gray-900">Документы</h6>
                        <GenericTable 
                            data={props.partnerDocuments.entities ? props.partnerDocuments.entities.filter(d => d.partner_id === parseInt(id)) : [] }
                            onDelete={(v) => dispatch(props.partnerDocumentsActions.drop({id: v}))}
                            columns={[
                                {name: "name", alias: "Название"},
                                {name: "download_url", alias: "URL", 
                                    mod: (obj_id, value) => <Button size="sm" component="a" target="__blank" href={value} startDecorator={<HiLink/>}>
                                        Открыть
                                    </Button>
                                }
                            ]}
                        />
                        <IconButton size="sm" onClick={() => 
                            {setDocModalOpen(!docModalOpen); setNewDoc({name: ""})}
                            }>
                            {docModalOpen ? <HiX/> : <HiPlus />}
                        </IconButton>   
                        {docModalOpen && 
                            <List>
                                <ListItem>
                                    <ListItemContent>
                                        <EditableTextField 
                                            value={newDoc.name}
                                            onApply={(v) => setNewDoc({
                                                ...newDoc,
                                                name: v
                                            })}
                                            labelComponent={<Typography
                                                level="body-sm"
                                                gutterBottom
                                                className="flex text-gray-500 align-middle"
                                            >
                                                Название документа
                                            </Typography>}
                                        />
                                    </ListItemContent>
                                </ListItem>
                                <ListItem>
                                    <ListItemContent>
                                        <Typography
                                            level="body-sm"
                                            gutterBottom
                                            className="flex text-gray-500 align-middle"
                                        >Укажите ссылку или загрузите файл</Typography>
                                        <Box className="grid grid-cols-4">
                                            <EditableTextField 
                                                className="col-span-3"
                                                value={newDoc.url}
                                                onApply={(v) => setNewDoc({
                                                    ...newDoc,
                                                    url: v,
                                                    file: null
                                                })}
                                            />
                                            <Button
                                                component="label"
                                                role={undefined}
                                                tabIndex={-1}
                                                variant="outlined"
                                                color="neutral"
                                                startDecorator={
                                                    newDoc?.file ? <HiCheckCircle/> : <HiDownload />
                                                }>
                                                Файл
                                                <input style={{
                                                    clip: "rect(0 0 0 0)",
                                                    clipPath: "inset(50%)",
                                                    height: "1px",
                                                    overflow: "hidden",
                                                    position: "absolute",
                                                    bottom: 0,
                                                    left: 0,
                                                    whiteSpace: "nowrap",
                                                    width: "1px"}}
                                                    onChange={(event)=>{
                                                        setNewDoc({
                                                            ...newDoc,
                                                            file: event.target.files[0],
                                                            url: null,
                                                        })
                                                }} type="file" />
                                            </Button>
                                        </Box>   
                                    </ListItemContent>
                                </ListItem>
                                <ListItem>
                                    <ListItemContent>
                                        {newDoc?.name && (newDoc.file || newDoc.url) && <Button
                                            onClick={uploadDocument}
                                        >
                                            Сохранить
                                        </Button>}
                                    </ListItemContent>
                                </ListItem>
                            </List>
                        }
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
      partners: state.partners,
      statuses: state.statuses,
      processings: state.processings,
      partnerTypes: state.partnerTypes,
      partnerRequisites: state.partnerRequisites,
      partnerContacts: state.partnerContacts,
      partnerDocuments: state.partnerDocuments,
      partnerDocumentsActions: partnerDocumentsActions,
      partnersActions: partnersActions,
      processingsActions: processingsActions,
      statusesActions: statusesActions,
      partnerTypesActions: partnerTypesActions,
      partnerDocumentsActions: partnerDocumentsActions,
      partnerRequisitesActions: partnerRequisitesActions,
      partnerContactsActions: partnerContactsActions,
      vacanciesActions: vacanciesActions,
    };
};

export default connect(mapStateToProps)(PartnerPage);
