import { connect } from "react-redux";
import Sidebar from "./sidebar";
import MainNavbar from "./navbar";
import { Box, CircularProgress } from "@mui/joy";
import { LoadingProvider, useLoading } from "../context/LoadingContext";
import RecruitmentDetailPage from "../pages/RecruitmentDetailPage";
import PartnerDetailPage from "../pages/PartnerDetailPage";
import { useSearchParams } from "react-router-dom";
import VacancyDetailPage from "../pages/VacancyDetailPage";

export const Layout = ({ children, loading }) => {
  return <LayoutContent loading={loading}>{children}</LayoutContent>;
};

const LayoutContent = ({ children, loading }) => {
  return (
    <Box className="flex min-h-dvh">
      <MainNavbar />
      <Sidebar />
      {loading && (
        <Box className="z-50 absolute w-full h-full backdrop-blur-md flex justify-center items-center">
          <CircularProgress />
        </Box>
      )}
      <MainContent>{children}</MainContent>
    </Box>
  );
};

const MainContent = ({ children }) => {
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');
  return (
    <Box
      component="main"
      sx={{ marginTop: "var(--Header-height)" }}
      className="MainContent px-6 md:px-6 pb-2 md:pb-3 pt-4 flex flex-1 flex-col min-w-0 h-dvh gap-1"
    >
      <RecruitmentDetailPage />
      <PartnerDetailPage />
      <VacancyDetailPage />
      {children}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.recruitments.loading 
    || state.statuses.loading 
    || state.processings.loading
    || state.partners.loading
    || state.vacancies.loading
    || state.partnerTypes.loading
    || state.rateTypes.loading
    || state.scorings.loading
    || state.stacks.loading
    || state.users.loading
    || state.roles.loading
    || state.permissions.loading
  };
};

export default connect(mapStateToProps)(Layout);
