import { connect, useDispatch } from "react-redux";
import { Badge, Breadcrumb, Button, Drawer } from "flowbite-react";
import { useContext, useEffect, useReducer, useState } from "react";
import { HiExternalLink, HiFire, HiHome, HiLink, HiPencil, HiPlus, HiSave, HiX } from "react-icons/hi";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  EditableBadgeField,
  EditableLinkField,
  EditableMultiLinkField,
  EditableTextField,
} from "../components/editableField";
import Layout from "../components/layout";
import { AuthContext } from "../context/AuthContext";
import {
  useFetchStatuses,
  fetchStack,
  fetchUsers,
  useFetchVacancyDetail,
  useJobRelatedActions,
  useJobVacancyActions,
  fetchPartners,
  useFetchPartnerDetail,
  useFetchPartners,
  useFetchRecruitmentDetail,
  fetchVacancies,
  usePartnerActions,
} from "../hooks/useApi";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemContent,
  Typography,
} from "@mui/joy";
import { useLoading } from "../context/LoadingContext";
import Kanban from "../components/kanban";
import GenericTable from "../components/tableAdmin";
import { vacanciesActions } from "../slices/vacancies";
import { partnersActions } from "../slices/partners";
import { processingsActions } from "../slices/processings";
import { recruitmentsActions } from "../slices/recruitments";
import { rateTypesActions } from "../slices/rateTypes";


const RecruitmentDetail = (props) => {
    const { user } = useContext(AuthContext);
    const dispatch = useDispatch();

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ emplCreated, setEmplCreated ] = useState(false);
    const id = searchParams.get('id');
    const view = searchParams.get('view');

    const [ recruitment, setRecruitment ] = useState({
        id: null,
        recruiter: user.partner,
        status: {}
    }); 

    const [ processingModalOpen, setProcessingModalOpen ] = useState(false);

    const [ processing, setProcessing ] = useState([]);

    async function handleSearchPartner(query, filter) {
        return props.partners.entities.filter(
          (partner) =>
            partner.name.includes(query) &&
            (filter ? filter === partner.type.id : true),
        );
    }

    function handleUpdateRecruitment(query, field) {
        let rec = {...recruitment};
        rec[field] = query;
        setRecruitment(rec);
        if (recruitment.id) {
          dispatch(props.recruitmentsActions.update({id: recruitment.id, data: { [field]: query }}));
        }
    }


    async function handleSelectStatus(query) {
        let rec = {...recruitment};
        rec.status = query;
        if (recruitment.id) {
            dispatch(props.recruitmentsActions.update({id: recruitment.id, data: { status: query }}));
        }
    }

    async function handleSearchVacancy(query) {
        if (
            (!props.vacancies.entities ||
                props.vacancies.entities.length === 0) &&
            !props.vacancies.loading
        )
            dispatch(props.vacanciesActions.getAll());
        return props.vacancies.entities?.filter((v) => 
            v.title?.toLowerCase().includes(query.toLowerCase()) 
        || v.external_id?.includes(query)
        )
    }

    async function handleCreateEmployee(name) {
        dispatch(props.partnersActions.create({data: {
            name: name,
            types: [7],
            status: 6,
        }}))
        setEmplCreated(true);
    }

    async function handleSaveRecruitment() {
        if (!recruitment?.employee?.id) {
            window.alert("Кандидат обязателен")
            return
        }; 
        if (!recruitment?.recruiter?.id) {
            window.alert("Рекрутер обязателен")
            return
        };
        dispatch(props.recruitmentsActions.create({data: {
            employee: recruitment.employee.id,
            recruiter: recruitment.recruiter.id,
            status: recruitment.status.id,
            comment: recruitment.comment,
        }}));
    }

    useEffect(()=>{
        if (emplCreated) {
            setRecruitment({
                ...recruitment,
                employee: props.partners.lastUpdated
            })
            if (window.confirm("Открыть редактор кандидата?")) window.open(`/#/partners?id=${props.partners.lastUpdated.id}`, '_blank')
            setEmplCreated(false);
        }
    }, [props.partners.lastUpdated])

    useEffect(()=>{
        setRecruitment(
            id != "new" 
            ? props.recruitments.entities.find(r => r.id === parseInt(id))
            : {
                id: null,
                name:  "Новый рекрутмент",
                recruiter: user.partner,
                status: props.statuses.entities.find(s => s.relation === 'recruitment' && s.weight === 0)
            }
        );
    }, [props.recruitments.entities])

    useEffect(()=>{
        if (view === "recruitment" && id) {
            if ((!props.partners.entities || props.partners.entities.length === 0) && !props.partners.loading) dispatch(props.partnersActions.getAll());
            if (id && id != "new") 
                { 
                    if ((!props.processings.entities || props.processings.entities.length === 0) && !props.processings.loading) dispatch(props.processingsActions.getAll());
                    if (
                        (!props.vacancies.entities ||
                            props.vacancies.entities.length === 0) &&
                        !props.vacancies.loading
                    )
                        dispatch(props.vacanciesActions.getAll());
                }
        };
    }, []);

    useEffect(()=>{
        setRecruitment(
            id != "new" 
            ? props.recruitments.entities.find(r => r.id === parseInt(id))
            : {
                id: null,
                name:  "Новый рекрутмент",
                recruiter: user.partner,
                status: props.statuses.entities.find(s => s.id === 23)
            }
        );
        if ((!props.partners || props.partners.entities.length === 0) && !props.partners.loading) dispatch(props.partnersActions.getAll());
        if ((!props.rateTypes || props.rateTypes.entities.length === 0) && !props.rateTypes.loading) dispatch(props.rateTypesActions.getAll());
        if ((!props.processings.entities || props.processings.entities.length === 0) && !props.processings.loading) dispatch(props.processingsActions.getAll());
    }, [id])

    return (
        props.statuses.entities.filter(s => ["recruitment"].includes(s.relation)).length > 0 &&
        view === "recruitment" && id && recruitment && (
            <Drawer
                open={recruitment ? true : false}
                onClose={() => setSearchParams()}
                position="right"
                className="w-11/12 md:w-2/3 mt-[var(--Header-height)] md:mt-0"
                style={{
                marginTop: {
                    xs: "var(--Header-height)",
                    sm: 0,
                },
                }}
            >
                <div className="flex flex-row justify-between place-items-center">
                    <Typography className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                        {recruitment?.id ? recruitment.name : "Новый рекрутмент"}
                    </Typography>
                    <Box className="w-fit">
                        {recruitment?.id ? (
                        <Box className="flex flex-col items-end justify-end align-bottom">
                            <Typography
                                level="body-sm"
                                gutterBottom
                                className="text-gray-500"
                            >
                            Дата начала:{" "}
                            {new Date(recruitment?.created_at).toLocaleDateString("ru-RU", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            })}
                            </Typography>
                            <Typography
                                level="body-sm"
                                gutterBottom
                                className="text-gray-500"
                            >
                            Дата обновления:{" "}
                            {new Date(recruitment?.updated_at).toLocaleDateString("ru-RU", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            })}
                            </Typography>
                        </Box>
                        ) : (
                        <IconButton
                            size="lg"
                            variant="solid"
                            onClick={handleSaveRecruitment}
                        >
                            <HiSave />
                        </IconButton>
                        )}
                    </Box>
                </div>
                <div className="pt-8">
                    <div className="rounded-lg bg-white p-4 shadow sm:p-6 xl:p-8 space-y-2">
                        <div>
                            <h6 className="text-lg text-gray-900">Детали</h6>
                            <List>
                                <ListItem>
                                    <ListItemContent>
                                        <EditableLinkField
                                        value={recruitment?.employee}
                                        labelComponent={
                                            <Typography
                                            level="body-sm"
                                            gutterBottom
                                            className="text-gray-500"
                                            >
                                                Кандидат
                                            </Typography>
                                        }
                                        onSearch={(v) => handleSearchPartner(v)}
                                        onCreate={(v) => handleCreateEmployee(v)}
                                        onApply={(v) => handleUpdateRecruitment(v, 'employee')}
                                        />
                                    </ListItemContent>
                                </ListItem>
                                <ListItem>
                                    <ListItemContent>
                                        <EditableLinkField
                                        value={recruitment?.recruiter}
                                        labelComponent={
                                            <Typography
                                            level="body-sm"
                                            gutterBottom
                                            className="text-gray-500"
                                            >
                                                Рекрутер
                                            </Typography>
                                        }
                                        onSearch={(v) => handleSearchPartner(v)}
                                        onApply={(v) => handleUpdateRecruitment(v, 'recruiter')}
                                        />
                                    </ListItemContent>
                                </ListItem>
                                <ListItem>
                                    <ListItemContent>
                                        <EditableBadgeField
                                        value={recruitment?.status?.id}
                                        badges={props.statuses.entities.filter(s => ["recruitment"].includes(s.relation))}
                                        onApply={(v) => handleSelectStatus(v)}
                                        labelComponent={
                                            <Typography
                                            level="body-sm"
                                            gutterBottom
                                            className="text-gray-500"
                                            >
                                                Статус
                                            </Typography>
                                        }
                                        />
                                    </ListItemContent>
                                </ListItem>
                                <ListItem>
                                    <ListItemContent>
                                        <EditableTextField
                                        labelComponent={
                                            <Typography
                                            level="body-sm"
                                            gutterBottom
                                            className="text-gray-500"
                                            >
                                                Комментарий
                                            </Typography>
                                        }
                                        value={recruitment?.comment}
                                        onApply={(v) => {
                                            handleUpdateRecruitment(v, "comment");
                                        }}
                                        className="text-sm"
                                        />
                                    </ListItemContent>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </div>
                {recruitment?.id && <div className="pt-8">
                    <div className="rounded-lg bg-white p-4 shadow sm:p-6 xl:p-8 space-y-2">
                        <div>
                        <h6 className="text-lg text-gray-900">Процессинг кандидатов</h6>
                        </div>
                        {!processingModalOpen && <GenericTable
                            data={props.processings.entities ? props.processings.entities.filter(p => p.recruitment?.id === recruitment.id ) : []}
                            columns={[
                                {name: "vacancy", alias: "Вакансия", 
                                    mod: (obj_id, value) => (
                                        <EditableLinkField
                                        value={value}
                                        onApply={(v) => {
                                            dispatch(props.processingsActions.update({id: obj_id, data: {vacancy: v}}));
                                        }}
                                        onSearch={handleSearchVacancy}
                                        />
                                    )
                                },
                                {name: "employee", alias: "Кандидат", 
                                mod: (obj_id, value) => (
                                    <EditableLinkField 
                                    value={value}
                                    onApply={(v) => {
                                        dispatch(props.processingsActions.update({id: obj_id, data: {employee: v}}));
                                    }}
                                    onSearch={handleSearchPartner}
                                    />
                                )
                                },
                                {name: "rate", alias: "Рейт",
                                    mod: (obj_id, value) => (
                                        <EditableTextField 
                                        value={value}
                                        onApply={(v) => {
                                            dispatch(props.processingsActions.update({id: obj_id, data: {rate: v}}));
                                        }}
                                        />
                                    )
                                },
                                {name: "rate_type", alias: "Тип рейта", 
                                    mod: (obj_id, value) => (
                                        <EditableBadgeField
                                            value={value?.id}
                                            onApply={(v) => {
                                                dispatch(props.processingsActions.update({id: obj_id, data: {rate_type: v}}));
                                            }}
                                            badges={[
                                                { id: 1, color: "info", name: "Часовой" },
                                                { id: 2, color: "info", name: "Дневной" },
                                                { id: 3, color: "info", name: "Договорной" },
                                            ]}/>
                                    )
                                    },
                                {name: "status", alias: "Статус", order: "weight",
                                mod: (obj_id, value) => (
                                    <EditableBadgeField
                                    value={value?.id}
                                    badges={props.statuses.entities.filter(s => ["processing"].includes(s.relation))}
                                    onApply={(v) => {
                                        dispatch(props.processingsActions.update({id: obj_id, data: {status: v}}));
                                        
                                    }}
                                    />
                                )
                                },
                                {name: "comment", alias: "Комментарий",
                                mod: (obj_id, value) => (
                                    <EditableTextField 
                                    value={value}
                                    onApply={(v) => {
                                        dispatch(props.processingsActions.update({id: obj_id, data: {comment: v}}));
                                    }}
                                    />
                                )
                                }
                            ]}
                            orderableColumns={["status"]}
                            onDelete={(v) => {dispatch(props.processingsActions.drop({id: v}));}}
                            additionalActions={[
                                ({
                                    action: (obj) => window.open(`/vacancies?view=vacancy&id=${obj.vacancy.id}`, '_blank'), 
                                    decorator:<HiExternalLink/>})
                            ]}
                        />}        
                        <IconButton size="sm" onClick={() => {
                        if (!processingModalOpen) {
                            dispatch(props.processingsActions.updateState([
                                ...props.processings.entities,
                                {
                                    vacancy: null,
                                    employee: recruitment.employee,
                                    recruitment: recruitment, 
                                    status: props.statuses.entities.find(s => s.relation === "processing" && s.weight === 0)?.id,
                                    comment: "Новый",
                                }
                            ]));
                            setProcessingModalOpen(true);
                        } else {
                            dispatch(props.processingsActions.updateState(
                                props.processings.entities.filter(p => p.id)
                            ));
                            setProcessingModalOpen(false);
                        }
                        }} >
                            {processingModalOpen ? <HiX /> : <HiPlus />}
                        </IconButton>
                        {processingModalOpen &&
                        <IconButton disabled={
                            !(props.processings.entities.find(p => !p.id)?.employee
                            && props.processings.entities.find(p => !p.id)?.status)
                            } size="sm" onClick={async ()=>{
                            let p = props.processings.entities.find(p => !p.id);
                            dispatch(props.processingsActions.updateState(
                                props.processings.entities.filter(p => p.id)
                            ));
                            dispatch(props.processingsActions.create({data:
                                {
                                    vacancy: p.vacancy.id,
                                    employee: p.employee.id,
                                    recruitment: p.recruitment.id,
                                    status: p.status.id,
                                    comment: p.comment
                                }}
                            ));
                        setProcessingModalOpen(false);
                        }}>
                        <HiSave />
                        </IconButton> }
                        {processingModalOpen && (
                        <List>
                            <ListItem>
                            <ListItemContent>
                                <EditableLinkField
                                value={props.processings.entities.find(p => !p.id)?.vacancy}
                                onApply={(v) => {
                                    let processing = {...props.processings.entities.find(p => !p.id)};
                                    processing.vacancy = v;
                                    dispatch(props.processingsActions.updateState(
                                        [
                                            ...props.processings.entities.filter(p => p.id),
                                            processing
                                        ]
                                    ));
                                }}
                                onSearch={handleSearchVacancy}
                                labelComponent={
                                    <Typography
                                    level="body-sm"
                                    gutterBottom
                                    className="text-gray-500"
                                    >
                                    Вакансия
                                    </Typography>}
                                />
                            </ListItemContent>
                            </ListItem>
                            <ListItem>
                            <ListItemContent>
                                <EditableLinkField
                                blocked
                                value={props.processings.entities.find(p => !p.id)?.employee}
                                onApply={(v) => {
                                    let processing = {...props.processings.entities.find(p => !p.id)};;
                                    processing.employee = v;
                                    dispatch(props.processingsActions.updateState(
                                        [
                                            ...props.processings.entities.filter(p => p.id),
                                            processing
                                        ]
                                    ));
                                }}
                                onSearch={handleSearchPartner}
                                labelComponent={
                                    <Typography
                                    level="body-sm"
                                    gutterBottom
                                    className="text-gray-500"
                                    >
                                    Кандидат
                                    </Typography>}
                                />
                            </ListItemContent>
                            </ListItem>
                            <ListItem>
                            <ListItemContent>
                                <EditableLinkField
                                    value={recruitment}
                                    onApply={() => {}}
                                    blocked
                                    onSearch={() => {}}
                                    labelComponent={
                                        <Typography
                                        level="body-sm"
                                        gutterBottom
                                        className="text-gray-500"
                                        >
                                        Процесс найма
                                        </Typography>}
                                />
                            </ListItemContent>
                            </ListItem>
                            <ListItem>
                            <ListItemContent>
                                <EditableBadgeField
                                value={
                                    props.processings.entities.find(p => !p.id)?.status 
                                    ? props.processings.entities.find(p => !p.id)?.status
                                    : props.statuses.entities.find(s => s.relation === "processing" && s.weight === 0)
                                }
                                badges={props.statuses.entities.filter(s => s.relation === "processing")}
                                onApply={(v) => {
                                    let processing = {...props.processings.entities.find(p => !p.id)};
                                    processing.status = v;
                                    dispatch(props.processingsActions.updateState(
                                        [
                                            ...props.processings.entities.filter(p => p.id),
                                            processing
                                        ]
                                    ));
                                }}
                                labelComponent={
                                    <Typography
                                    level="body-sm"
                                    gutterBottom
                                    className="text-gray-500"
                                    >
                                    Статус
                                    </Typography>}
                                />
                            </ListItemContent>
                            </ListItem>
                            <ListItem>
                                <ListItemContent>
                                    <EditableBadgeField
                                            value={props.processings.entities.find(p => !p.id)?.rate_type}
                                            onApply={(v) => {
                                                let processing = {...props.processings.entities.find(p => !p.id)};
                                                processing.rate_type = props.rateTypes.entities.find(rt => rt.id === parseInt(v));
                                                dispatch(props.processingsActions.updateState(
                                                    [
                                                        ...props.processings.entities.filter(p => p.id),
                                                        processing
                                                    ]
                                                ));
                                            }}
                                            
                                            badges={[
                                                { id: 1, color: "info", name: "Часовой" },
                                                { id: 2, color: "info", name: "Дневной" },
                                                { id: 3, color: "info", name: "Договорной" },
                                            ]}/>
                                </ListItemContent>
                            </ListItem>
                            <ListItem>
                            <ListItemContent>
                                <EditableTextField
                                value={props.processings.entities.find(p => !p.id)?.comment}
                                onApply={(v) => {
                                    let processing = {...props.processings.entities.find(p => !p.id)};
                                    processing.comment = v;
                                    dispatch(props.processingsActions.updateState(
                                        [
                                            ...props.processings.entities.filter(p => p.id),
                                            processing
                                        ]
                                    ));
                                }}
                                labelComponent={
                                    <Typography
                                    level="body-sm"
                                    gutterBottom
                                    className="text-gray-500"
                                    >
                                    Комментарий
                                    </Typography>}
                                />
                            </ListItemContent>
                            </ListItem>
                        </List>
                        )}
                    </div>
                    </div>}
            </Drawer>
        )
    )
};

const mapStateToProps = (state) => {
    return {
      recruitments: state.recruitments,
      statuses: state.statuses,
      vacancies: state.vacancies,
      processings: state.processings,
      partners: state.partners,
      rateTypes: state.rateTypes,
      vacanciesActions: vacanciesActions,
      partnersActions: partnersActions,
      processingsActions: processingsActions,
      recruitmentsActions: recruitmentsActions,
      rateTypesActions: rateTypesActions
    };
};

export default connect(mapStateToProps)(RecruitmentDetail);