import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { API_BASE_URL } from '../config';
import { Pie, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import Layout from '../components/layout';
import { recruitmentsActions } from '../slices/recruitments';
import { vacanciesActions } from '../slices/vacancies';
import { partnersActions } from '../slices/partners';
import { processingsActions } from '../slices/processings';
import { usersActions } from '../slices/users';
import GenericTable from '../components/tableAdmin';
import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';

const DashboardPage = (props) => {
  const [stats, setStats] = useState(null)

  useEffect(() => {

  }, []);

  return (
    <div class="overflow-x-auto h-full">
      <div>
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/">
            <div className="flex items-center gap-x-3">
              <HiHome className="text-xl" />
              <span className="dark:text-white">Админка</span>
            </div>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Статистика</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
          Статистика
        </h1>
      </div>
      <div className="mt-4 h-full">
        {stats ? <>В разработке</> :
          <GenericTable
            data={[
              {key: "Статус разработи дашборда", value: "В работе"},
              {key: "Любимое число", value: "$1000000"},
            ]}
            columns={[
              {name: "key", alias: "Статистика"},
              {name: "value", alias: "Значение"}
            ]}
          />
        }
      </div>
    </div>
  );
};

export default DashboardPage;