import { useContext, useEffect } from "react"
import { AuthContext } from "../context/AuthContext"
import { Navigate, useSearchParams } from "react-router-dom";

export const AuthCallback = () => {
    const { callback } = useContext(AuthContext);

    const [ searchParams ] = useSearchParams();

    useEffect(()=>{
        callback(searchParams);
    }, [searchParams])

    console.log(window.location.href);
    return (
        <Navigate to="/" />
    )
}