import { connect, useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import {
  useFetchStatuses,
  fetchVacancies,
  useJobVacancyActions,
  useFetchRateTypes,
  useJobRelatedActions,
} from "../hooks/useApi";
import { AuthContext } from "../context/AuthContext";
import Layout from "../components/layout";
import { useLoading } from "../context/LoadingContext";
import {
  Badge,
  Breadcrumb,
  Button,
  Drawer,
  Label,
  TextInput,
} from "flowbite-react";
import {
  HiFire,
  HiHome,
  HiPlus,
  HiViewBoards,
  HiViewList,
} from "react-icons/hi";
import Kanban from "../components/kanban";
import { VacancyDetail } from "./VacancyDetailPage";
import {
  Box,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Typography,
} from "@mui/joy";
import GenericTable from "../components/tableAdmin";
import { BASE_NEW_RATE_TYPE } from "../config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditableMultiLinkField } from "../components/editableField";
import { recruitmentsActions } from "../slices/recruitments";
import { statusesActions } from "../slices/statuses";
import RecruitmentDetailPage from "./RecruitmentDetailPage";


const RecruitmentCard = (props) => {
    return (
      <div
        key={props.data.id}
        className="p-2 rounded shadow-sm border-gray-100 border-2"
      >
        <h3
          className="text-md mb-3 text-gray-900 hover:text-cyan-700"
          onClick={() => props.onCardClick({ id: props.data.id })}
        >
          {props.data.recruiter.name}
        </h3>
        <div className="flex flex-row items-center mt-2 text-sm gap-2">
          {/* {data.stacks.map((stack) => (
            <Badge key={stack.id} color="green">
              {stack.name}
            </Badge>
          ))} */}
        </div>
        <div className="flex flex-col items-start mt-2">
            <a
                target="_blank"
                className="text-sm text-gray-700 hover:text-cyan-700"
                href={`/partners/${props.data.employee.id}`}
            >
                <Typography level="body-sm">Кандидат</Typography>{props.data.employee.name}
            </a>
            <Typography level="body-sm">
                Процессинги - {props.data.processings.length}
            </Typography>
        </div>
      </div>
    );
  };

const RecruitmentPage = (props) => {
    const dispatch = useDispatch();

    const [updateTrigger, setUpdateTrigger] = useState(0);
    const [viewKanban, setViewKanban] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();



    const handleCardDrop = async (id, status_id) => {
        status_id = parseInt(status_id);
        dispatch(
            props.recruitmentsActions.update({id: parseInt(id), data: {
                status: status_id
            }}));
    };

    useEffect(() => {
        dispatch(props.recruitmentsActions.getAll())
    }, [props.processings.entities])
    
    useEffect(() => {
        if (!props.recruitments.loading) dispatch(props.recruitmentsActions.getAll())
        if (!props.statuses.loading) dispatch(props.statusesActions.getAll())
    }, []);

    return (
        <>
            <div class="overflow-x-auto h-full">
                <div>
                    <Breadcrumb className="mb-4">
                        <Breadcrumb.Item href="/">
                        <div className="flex items-center gap-x-3">
                            <HiHome className="text-xl" />
                            <span className="dark:text-white">Админка</span>
                        </div>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Рекрутинг</Breadcrumb.Item>
                    </Breadcrumb>
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                        Рекрутинг
                    </h1>
            </div>
            <Box className="flex flex-row justify-between">
            <Box className="space-x-2 pt-4">
                <IconButton
                size="md"
                variant={viewKanban ? "solid" : "outlined"}
                onClick={() => setViewKanban(true)}
                >
                <HiViewBoards />
                </IconButton>
                <IconButton
                size="md"
                variant={viewKanban ? "outlined" : "solid"}
                onClick={() => setViewKanban(false)}
                >
                <HiViewList />
                </IconButton>
            </Box>
            <Button
                color="gray"
                className="mt-4 ml-1"
                onClick={() => {
                setSearchParams({view: 'recruitment', id: 'new'})
                }}
            >
                <div className="flex items-center gap-x-3">
                <HiPlus className="text-xl" />
                <span>Добавить</span>
                </div>
            </Button>  
            </Box>
            <div className="mt-4 h-full">
                {viewKanban 
                ? (
                    <Kanban
                        onDragEnd={(cardId, colId) => handleCardDrop(cardId, colId)}
                        filters={[
                            { label: "Статус", column: "status", placeholder: "Статус" },
                            { label: "Рекрутер", column: "recruiter", placeholder: "Рекрутер" },
                        ]}
                        columns={
                            props.statuses.entities.filter(s => ["recruitment"].includes(s.relation)).slice().sort(
                                (a, b) => a.weight - b.weight,
                            )?.filter((v) => v.weight < 6)
                            ?.map((s) => ({
                                id: s.id,
                                title: s.name,
                                color: s.color,
                                cards: props.recruitments.entities
                                    ?.filter((recruitment) => recruitment.status?.id === s.id)
                                    ?.map((recruitment) => ({
                                        id: recruitment?.id,
                                        name: recruitment.title,
                                        child: (
                                            <RecruitmentCard 
                                                data={recruitment}
                                                onCardClick={(v) => {
                                                    setSearchParams({view: 'recruitment', id: v.id})
                                                }}
                                            />
                                        ),
                                    }))
                            }))

                        }
                    />
                ) 
                : (
                    <GenericTable
                        data={props.recruitments.entities}
                        columns={[
                            { name:"id", alias: "ID" },
                            { name:"employee", alias: "Кандидат" },
                            { name:"recruiter", alias: "Рекрутер" },
                            { name:"status", alias: "Статус", badges: props.statuses.entities.filter(s => s.relation === "recruitment")},
                            { name:"comment", alias: "Комментарий" },
                        ]}
                        filters={[
                            { label: "Статус", column: "status", placeholder: "Статус" },
                            { label: "Рекрутер", column: "recruiter", placeholder: "recruiter" },
                        ]}
                        onDelete={(v) => console.log(v)}
                        onClick={(v) => {
                                setSearchParams({view: 'recruitment', id: v.id})
                            }}
                        renderCard={(data) => <RecruitmentCard data={data} onCardClick={()=>{}} />}
                    />
                )}
            </div>
        </div>
        </>
    )

    
}

const mapStateToProps = (state) => {
    return {
      recruitments: state.recruitments,
      statuses: state.statuses,
      processings: state.processings,
      recruitmentsActions: recruitmentsActions,
      statusesActions: statusesActions,
    };
};

export default connect(mapStateToProps)(RecruitmentPage);