import Layout from "../components/layout";
import { connect, useDispatch } from "react-redux";
import {
    Badge,
    Breadcrumb,
    Button,
    Label,
    Modal,
    Select,
    TextInput,
} from "flowbite-react";
import { HiHome, HiPlus } from "react-icons/hi";
import { Box, Typography } from "@mui/joy";
import { useContext, useEffect, useReducer, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import GenericTable from "../components/tableAdmin";
import { useFetchPartnerTypes, useFetchStatuses, useUtilsActions } from "../hooks/useApi";
import {
    EditableBadgeField,
    EditableTextField,
} from "../components/editableField";
import { useSearchParams } from "react-router-dom";
import { statusesActions } from "../slices/statuses";
import { partnerTypesActions } from "../slices/partnerTypes";
import SettingsStatusesPage from "./SettingsStatusesPage";
import SettingsPartnerTypesPage from "./SettingsPartnerTypesPage";

const SettingsPage = () => {
    const [searchParams] = useSearchParams();
    const view = searchParams.get("view")
    console.log(view);
    return (
        <>
            <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/">
                    <div className="flex items-center gap-x-3">
                        <HiHome className="text-xl" />
                        <span className="dark:text-white">Админка</span>
                    </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Настройки</Breadcrumb.Item>
                {view === "statuses" && <Breadcrumb.Item>Статусы</Breadcrumb.Item>}
                {view === "partner_types" && <Breadcrumb.Item>Типы партнеров</Breadcrumb.Item>}
            </Breadcrumb>
            <Box className="flex mb-1 gap-1 sm:flex-col md:flex-row sm:items-start md:items-center flex-wrap justify-between">
                <Typography level="h2" component="h1">
                    Настройки
                </Typography>
            </Box>
            {view === "statuses" && <SettingsStatusesPage />}
            {view === "partner_types" && <SettingsPartnerTypesPage />}
        </>
    );
};

export default SettingsPage;