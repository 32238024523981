import { connect, useDispatch } from "react-redux";
import {
    Breadcrumb,
    Button,
    Label,
    Modal,
    Select,
    Table,
    TextInput,
} from "flowbite-react";
import { AuthContext } from "../context/AuthContext";
import { API_BASE_URL } from "../config";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Layout from "../components/layout";
import { useLoading } from "../context/LoadingContext";
import {
    HiHome,
    HiPencil,
    HiPlus,
    HiTrash,
    HiViewBoards,
    HiViewList,
} from "react-icons/hi";
import {
    useFetchPartners,
    usePartnerActions,
    useFetchPartnerTypes,
    useFetchStatuses,
} from "../hooks/useApi";
import GenericTable from "../components/tableAdmin";
import { Box, Drawer, IconButton, Link, Typography } from "@mui/joy";
import Kanban from "../components/kanban";
import PartnerDetailPage from "./PartnerDetailPage";
import { useSearchParams } from "react-router-dom";
import { EditableMultiLinkField } from "../components/editableField";
import { partnersActions } from "../slices/partners";
import { statusesActions } from "../slices/statuses";
import { partnerTypesActions } from "../slices/partnerTypes";
import { useCustomSearchParams } from "../components/utils";

const PartnerCard = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <div
            key={props.data.id}
            className="p-2 rounded shadow-sm border-gray-100 border-2"
        >
            <h3
                className="text-md mb-3 text-gray-900 hover:text-cyan-700"
                onClick={() => setSearchParams({ view: "partner", id: props.data.id })}
            >
                {props.data.name}
            </h3>
            <p className="text-sm text-cyan-700">Тип: {props.data.types?.map(t => t.name).join(", ")}</p>
        </div>
    );
};

const PartnerAdminPage = (props) => {
    const dispatch = useDispatch();
    const [searchObject, searchParams, setSearchParams] = useCustomSearchParams();
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalId, setModalId] = useState(null);
    const [modalName, setModalName] = useState("");
    const [modalType, setModalType] = useState([{ id: 0, name: "Партнер" }]);
    const [modalStatus, setModalStatus] = useState({
        id: null,
        color: "info",
        name: "Новый",
    });
    const [viewKanban, setViewKanban] = useState(true);

    const handleModalCommit = async () => {
        if (modalId) {
            dispatch(props.partnersActions.update({id: modalId, data:{
                name: modalName,
                types: modalType.map(t => t.id),
                status: modalStatus?.id,
            }}));    
        } else {
            dispatch(props.partnersActions.create({data:{
                name: modalName,
                types: modalType.map(t => t.id),
                status: modalStatus?.id,
            }}));
        }
        
    };

    const handleEditPartner = React.useCallback(
        (data) => {
            setModalId(data.id);
            setModalName(data.name);
            setModalType(data.types);
            const status = props.statuses.entities.find(
                (s) =>
                    s.id === data.status ||
                    s.id.toString() === data.status ||
                    s.id === 6,
            ) || { id: 6 };
            setModalStatus(status);
            setModalOpen(true);
        },
        [props.statuses.entities],
    );

    const handleDeletePartner = async (id) => {
        if (window.confirm("Этого партнера точно нужно удалить?")) {
            dispatch(props.partnersActions.drop({id: id}))
        }
    };

    const handleSelectPartner = ( partner ) => {
        setSearchParams({
            ...searchObject,
            view: "partner", 
            id: partner.id})
    };

    async function handleSearchPartner(query) {
        setSearchParams({
          ...searchObject,
          search: query
        })
    };

    function filtred(data) {
        const search = searchParams.get('search');
        if (search) {
            return data.filter(partner => partner.name.toLowerCase().includes(search.toLowerCase()))
        } else {
            return data
        }
    }

    const handleCardDrop = (id, status) => {
        dispatch(props.partnersActions.update({
            id: id,
            data: {
                status: status
            }
        }))
    };

    useEffect(() => {
        const defStatus = props.statuses.entities.find(status => status.relation === "partner" && status.weight === 0)
        if (!modalStatus?.id) {
            setModalStatus(defStatus);
        }

        const defPT = props.partnerTypes.entities.find(pt => pt.is_default)
        if (!modalType?.id) {
            setModalStatus(defPT);
        }
    }, [props.partnerTypes.entities, props.statuses.entities])

    useEffect(() => {
        console.warn("fetch partners", props)
        if ((!props.partners.entities || props.partners.entities.length === 0) && !props.partners.loading) dispatch(props.partnersActions.getAll());
        if ((!props.statuses.entities || props.statuses.entities.length === 0) && !props.statuses.loading) dispatch(props.statusesActions.getAll());
        if ((!props.partnerTypes.entities || props.partnerTypes.entities.length === 0) && !props.partnerTypes.loading) dispatch(props.partnerTypesActions.getAll());
    }, []);

    return (
        <>
            <Modal onClose={() => setModalOpen(false)} show={isModalOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                    <strong>Менеджер партнеров</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Наименования</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder='ООО "Рога и не рога"'
                                    value={modalName}
                                    onChange={(v) =>
                                        setModalName(v.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="types">Тип</Label>
                            <div className="mt-1">
                                <EditableMultiLinkField 
                                    value={modalType}
                                    onApply={setModalType}
                                    onSearch={(q) => {return props.partnerTypes.entities.filter((p) => p.name.toLowerCase().includes(q.toLowerCase()))}}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="status">Статус</Label>
                            <div className="mt-1">
                                <Select
                                    required
                                    id="status"
                                    value={modalStatus?.id}
                                    onChange={(v) => {
                                        const status = props.statuses.entities.find(
                                            (s) =>
                                                s.id ===
                                                parseInt(v.target.value),
                                        );
                                        setModalStatus(status);
                                    }}
                                >
                                    {props.statuses.entities.filter(s => s.relation === 'partner')?.map((v) => (
                                        <option key={v.id} value={v.id}>
                                            {v.name}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div>
                            <Button
                                color="gray"
                                onClick={async () => {
                                    await handleModalCommit();
                                    setModalOpen(false);
                                }}
                            >
                                <div className="flex items-center gap-x-3">
                                    <HiPlus className="text-xl" />
                                    <span>Сохранить</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/">
                    <div className="flex items-center gap-x-3">
                        <HiHome className="text-xl" />
                        <span className="dark:text-white">Админка</span>
                    </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Контрагенты</Breadcrumb.Item>
            </Breadcrumb>
            <Box className="flex mb-1 gap-1 sm:flex-col md:flex-row sm:items-start md:items-center flex-wrap justify-between">
                <Typography level="h2" component="h1">
                    Контрагенты
                </Typography>
            </Box>
            <Box className="space-x-2 pt-4">
                <IconButton
                    size="md"
                    variant={viewKanban ? "solid" : "outlined"}
                    onClick={() => setViewKanban(true)}
                >
                    <HiViewBoards />
                </IconButton>
                <IconButton
                    size="md"
                    variant={viewKanban ? "outlined" : "solid"}
                    onClick={() => setViewKanban(false)}
                >
                    <HiViewList />
                </IconButton>
            </Box>
            {viewKanban ? (
                <Kanban
                    onDragEnd={handleCardDrop}
                    onSearch={handleSearchPartner}
                    columns={props.statuses.entities.filter(s => ["partner"].includes(s.relation)).slice().sort(
                        (a, b) => a.weight - b.weight,
                    )?.filter((v) => v.weight < 10)
                        ?.map((s) => ({
                            id: s.id,
                            title: s.name,
                            color: s.color,
                            cards: filtred(props.partners.entities)
                                ?.filter(
                                    (partner) => partner.status?.id === s.id,
                                )
                                ?.map((partner) => ({
                                    id: partner.id,
                                    child: (
                                        <PartnerCard
                                            data={partner}
                                        />
                                    ),
                                })),
                        }))}
                    filters={[
                        {
                            label: "Тип партнера",
                            column: "types",
                            placeholder: "Тип",
                        },
                    ]}
                />
            ) : (
                <>
                    <GenericTable
                        data={filtred(props.partners.entities)}
                        onSearch={handleSearchPartner}
                        columns={[
                            { name: "id", alias: "ID" },
                            {
                                name: "name",
                                alias: "Наименование",
                                mod: (obj_id, value) => (
                                    <Link
                                        onClick={()=>setSearchParams({
                                            ...searchObject,
                                            view: "partner", 
                                            id: obj_id})}
                                    >
                                        {value}
                                    </Link>
                                ),
                            },
                            { name: "types", alias: "Тип" },
                            {
                                name: "status",
                                alias: "Статус",
                                badges: props.statuses.entities,
                            },
                        ]}
                        filters={[
                            {
                                label: "Тип партнера",
                                column: "types",
                                placeholder: "Тип",
                            },
                        ]}
                        orderableColumns={["name"]}
                        onDelete={handleDeletePartner}
                        onEdit={handleEditPartner}
                        onClick={handleSelectPartner}
                        checkbox
                        renderCard={(data) => <PartnerCard data={data} />}
                    />
                    <div className="flex items-left mt-4 p-1">
                        <Button
                            color="gray"
                            onClick={() => {
                                setModalOpen(true);
                                setModalId(null);
                                setModalName("");
                                setModalType([{ id: 1, name: "Партнер" }]);
                            }}
                        >
                            <div className="flex items-center gap-x-3">
                                <HiPlus className="text-xl" />
                                <span>Добавить</span>
                            </div>
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        statuses: state.statuses,
        partners: state.partners,
        partnerTypes: state.partnerTypes,
        statusesActions: statusesActions,
        partnersActions: partnersActions,
        partnerTypesActions: partnerTypesActions,
    };
  };
  
  export default connect(mapStateToProps)(PartnerAdminPage);
