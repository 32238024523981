import { connect, useDispatch } from "react-redux";
import {
    Badge,
    Breadcrumb,
    Select,
    TextInput
} from "flowbite-react";
import { AuthContext } from "../context/AuthContext";
import React, { useState, useContext, useEffect } from "react";
import Layout from "../components/layout";
import { HiEyeOff, HiHome, HiPencil, HiPlus, HiTrash } from "react-icons/hi";
import { HiEye } from "react-icons/hi2";
import { useUserActions, useFetchUsers, useFetchPartners, fetchRoles, useFetchRoles, fetchPartners, fetchUsers } from "../hooks/useApi";
import { Avatar, Box, Button, Chip, DialogTitle, Divider, FormControl, FormLabel, IconButton, List, ListDivider, ListItem, ListItemContent, ListItemDecorator, Modal, ModalClose, ModalDialog, TextField, Typography } from "@mui/joy";
import GenericTable from "../components/tableAdmin";
import { EditableLinkField } from "../components/editableField";
import { useLoading } from "../context/LoadingContext";
import { usersActions } from "../slices/users";
import { partnersActions } from "../slices/partners";

const UserAdminPage = (props) => {
    const { token } = useContext(AuthContext);
    const dispatch = useDispatch();

    const [updateTrigger, setUpdateTrigger] = useState(0);
    const { handleCommit, handleDelete } = useUserActions(token);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalId, setModalId] = useState(null);
    const [modalUsername, setModalUsername] = useState("");
    const [modalEmail, setModalEmail] = useState("");
    const [modalRole, setModalRole] = useState("USER");
    const [modalPwd, setModalPwd] = useState("");
    const [iconPwd, setIconPwd] = useState(false);
    const [pwdType, setPwdType] = useState("password");

    const { setIsLoading } = useLoading();

    const handleUpdate = () => {
        setUpdateTrigger(updateTrigger + 1);
    };

    const handlePartnerSearch = (v) => {
        return props.partners?.entities.filter(
            (partner) => partner.name.includes(v)
        );
    }

    const handlePwdToggle = () => {
        if (pwdType === "password") {
            setIconPwd(true);
            setPwdType("text");
        } else {
            setIconPwd(false);
            setPwdType("password");
        }
    };

    const handleModelCommit = async () => {
        try {
            await handleCommit(
                modalId,
                {username: modalUsername,
                email: modalEmail,
                role: {name: modalRole},
                password: modalPwd}
            );
            handleUpdate();
        } catch (error) {
            console.error("Failed to fetch data", error);
            if (error.response?.data?.message?.message) {
                window.alert(error.response.data.message.message);
            }
            // Handle error: show error message, etc.
        }
    };

    const handleUserDelete = async (id) => {
        if (window.confirm("Этого пользователя точно нужно удалить?")) {
            await handleDelete(id);
            handleUpdate();
        }
    };

    const handleUserEdit = (data) => {
        setModalId(
            data.id,
        );
        setModalUsername(
            data.username,
        );
        setModalEmail(
            data.email,
        );
        setModalRole(
            data.role?.name ? data.role?.name : 'USER',
        );
        setModalPwd("");
        setModalOpen(
            true,
        );
    };

    useEffect(()=>{
        if ((!props.partners.entities || props.partners.entities.length === 0) && !props.partners.loading) dispatch(props.partnersActions.getAll());
        dispatch(props.usersActions.getAll());
    }, []);
    
    return (
        <>
            <Modal size="lg" onClose={() => setModalOpen(false)} open={isModalOpen} sx={{marginTop: "var(--Header-height)"}}>
                <ModalDialog className="border-b border-gray-200 !p-6 dark:border-gray-700">
                    <ModalClose />
                    <DialogTitle>Редактор пользователей</DialogTitle>
                    <Divider sx={{my: 2}} />
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <div>
                                <FormLabel htmlFor="username">Юзернейм</FormLabel>
                                <div className="mt-1">
                                    <TextInput
                                        id="username"
                                        name="username"
                                        placeholder="Зубенко Михаил Петрович"
                                        value={modalUsername}
                                        onChange={(v) =>
                                            setModalUsername(v.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <div className="mt-1">
                                    <TextInput
                                        id="email"
                                        name="email"
                                        placeholder="mafioznik@aiotex.ru"
                                        type="email"
                                        value={modalEmail}
                                        onChange={(v) => {
                                            setModalEmail(v.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <FormLabel htmlFor="phone">Роль</FormLabel>
                                <div className="mt-1">
                                    {/* <Select
                                        id="role"
                                        value={modalRole}
                                        onChange={(v) =>
                                            setModalRole(v.target.value)
                                        }
                                    >
                                        {props.roles.map(role => <option value={role.name}>{role.name}</option>)}
                                    </Select> */}
                                </div>
                            </div>
                            <div>
                                <FormLabel htmlFor="passwordNew">
                                    Задать новый пароль
                                </FormLabel>
                                <div className="mt-1 flex">
                                    <TextInput
                                        id="passwordNew"
                                        name="passwordNew"
                                        placeholder="••••••••"
                                        type={pwdType}
                                        value={modalPwd}
                                        onChange={(v) =>
                                            setModalPwd(v.target.value)
                                        }
                                    />
                                    <span
                                        class="flex justify-stretch items-center"
                                        onClick={handlePwdToggle}
                                    >
                                        {iconPwd ? (
                                            <HiEyeOff class="ml-2" size={18} />
                                        ) : (
                                            <HiEye class="ml-2" size={18} />
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <Button
                                    color="gray"
                                    onClick={() => {
                                        handleModelCommit();
                                        setModalOpen(false);
                                    }}
                                >
                                    <div className="flex items-center gap-x-3">
                                        <HiPlus className="text-xl" />
                                        <span>Сохранить</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                </ModalDialog>
            </Modal>
            <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/">
                    <div className="flex items-center gap-x-3">
                        <HiHome className="text-xl" />
                        <span className="dark:text-white">Админка</span>
                    </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Пользователи</Breadcrumb.Item>
            </Breadcrumb>
            <Box className="flex mb-1 gap-1 sm:flex-col md:flex-row sm:items-start md:items-center flex-wrap justify-between">
                <Typography level="h2" component="h1">
                    Пользователи
                </Typography>
            </Box>
            {props?.users?.entities && <GenericTable
                data={props?.users.entities}
                columns={[

                    {name: "username", alias: "Имя"},
                    {name: "email", alias: "Email"},
                    {name: "roles", alias: "Роли"},
                    {name: "last_login", alias: "Последний вход"},
                    {name: "partner", alias: "Связанный контрагент", 
                        mod: (obj_id, value) => (
                            <EditableLinkField 
                                value={value.id ? value : null}
                                onSearch={handlePartnerSearch}
                                onApply={async (v) => {
                                    await handleCommit(obj_id, {partner: v.id})
                                    handleUpdate();
                                }}
                            />
                        )}
                ]}
                filters={[
                    {label: "Роль", column: "role", placeholder: "Роль"}
                ]}
                orderableColumns={["username", "email"]}
                onDelete={handleUserDelete}
                onEdit={handleUserEdit}
                renderCard={(data) => (
                    <List key={data.id} size="sm" sx={{ '--ListItem-paddingX': 0 }}>
                        <ListItem
                            className="flex justify-between items-start flex-row"
                        >
                            <ListItemContent sx={{ display: 'flex', gap: 3, alignItems: 'start' }}>
                                <ListItemDecorator>
                                    <Avatar color="success" size="sm">{Array.from(data.username)[0].toUpperCase()}</Avatar>    
                                </ListItemDecorator>
                                <div>
                                    <Typography gutterBottom sx={{ fontWeight: 600 }}>
                                        {data.username}
                                    </Typography>
                                    <Typography level="body-xs" gutterBottom>
                                        {data.email}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            gap: 0.5,
                                            mb: 1,
                                        }}
                                    >
                                        <Typography level="body-xs">{data.last_login}</Typography>
                                        <Typography level="body-xs">&bull;</Typography>
                                        <Typography level="body-xs">{data.id}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                        <IconButton
                                            aria-label="previous page"
                                            variant="outlined"
                                            color="neutral"
                                            size="sm"
                                        >
                                            <HiTrash/>
                                        </IconButton>
                                    </Box>
                                </div>  
                            </ListItemContent>
                            {data.roles}
                        </ListItem>
                        <ListDivider />
                    </List>
                )}
                checkbox
            />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
      users: state.users,
      partners: state.partners,
      usersActions: usersActions,
      partnersActions: partnersActions,
    };
};

export default connect(mapStateToProps)(UserAdminPage);
