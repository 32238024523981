import axios from "axios";
import { API_BASE_URL } from "../config";

export const apiRequest = (endpoint, method = "get", body = null, headers = null) => {
    let config = {
        withCreditianals: true,
        method,
        url: `${API_BASE_URL}${endpoint}`,
        ...body,
    };
    if (headers) config.headers = headers;
    return axios(config);
};

export const dataSevice = (endpoint) => {
    const apiBase = async (
        object,
        body = null,
        method = "GET",
        ep = "",
        headers = null,
    ) => {
        const dataOrParams = ["GET", "DELETE"].includes(method)
            ? "params"
            : "data";
        const target = `${endpoint}${ep ? '/'+ep : ""}${object ? '/'+object : ""}`
        return apiRequest(
            target,
            method,
            { [dataOrParams]: body },
            headers,
        );
    };

    return {
        base: (config) => apiBase(...config),
        getAll: (ep, data=null) => apiBase(null, data, "GET", ep),
        get: (id, ep) => apiBase(id, null, "GET", ep),
        create: (data, ep, headers) => apiBase(null, data, "POST", ep, headers),
        update: (id, data) =>
            apiBase(id, data, "PUT"),
        delete: (id, data) =>
            apiBase(id, data, "DELETE"),
    };
};

export const vacancyDataService = (accessToken) => {
    const services = dataSevice("/job_vacancies", accessToken);
    return {
        ...services,
        getPublic: services.base("public"),
    };
};

export const partnerDataService = (accessToken) => {
    return {
        ...dataSevice("/partners", accessToken),
        documents: (id) => dataSevice(`/partners/${id}/documents`),
        contacts: (id) => dataSevice(`/partners/${id}/contacts`),
        requisites: (id) => dataSevice(`/partners/${id}/requisites`),
    };
};

export const statusDataService = (accessToken) => {
    return dataSevice("/utils/status", accessToken);
};

export const rateTypeDataService = (accessToken) => {
    return dataSevice("/utils/rate_types", accessToken);
};

export const userDataService = (accessToken) => {
    return dataSevice("/users", accessToken);
};

export const permissionDataService = (accessToken) => {
    return dataSevice("/users/permissions", accessToken);
};

export const roleDataService = (accessToken) => {
    return dataSevice("/users/roles", accessToken);
};

export const partnerTypeDataService = (accessToken) => {
    return dataSevice("/utils/partner_types", accessToken);
};

export const processingDataService = (accessToken) => {
    return dataSevice("/job_related/processing", accessToken);
};

export const recruitmentDataService = (accessToken) => {
    return dataSevice("/job_related/recruitment", accessToken);
};

export const scoringDataService = (accessToken) => {
    return dataSevice("/job_related/scorings", accessToken);
};

export const scoringAnswerDataService = (accessToken) => {
    return dataSevice("/job_related/scoring-answers", accessToken);
};

export const scoringCriteriaDataService = (accessToken) => {
    return dataSevice("/job_related/scoring-criteria", accessToken);
};

export const stackDataService = (accessToken) => {
    return dataSevice("/job_related/stacks", accessToken);
};
