import React, { createContext, Component } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { redirect } from 'react-router-dom';

const AuthContext = createContext();

class AuthProvider extends Component {
    constructor(props) {
        super(props);

        // Attempt to restore authentication state from sessionStorage
        const savedAuthState = sessionStorage.getItem('authState');
        const initialState = savedAuthState
            ? JSON.parse(savedAuthState)
            : { isAuthenticated: false, user: null, token: null, roles: [] };

        this.state = initialState;
        this.setupInterceptors();
    }

    setupInterceptors = () => {
        // Intercept requests
        axios.interceptors.request.use(config => {
            const { token } = this.state;
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        // Intercept responses
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    this.setState({ isAuthenticated: false, user: null, token: null, roles: null }, () => {
                      sessionStorage.removeItem('authState');
                    });
                }
                return Promise.reject(error);
            }
        );
    }

    login = async () => {
        try {
          const responseMe = await axios.get(`${API_BASE_URL}/api/auth/status`);
          const { isAuthenticated, user } = responseMe.data;
          if ( !isAuthenticated ) {
            const response = await axios.get(`${API_BASE_URL}/api/auth/login?redirect_uri=${window.location.origin}/oidc-callback`);
            const { loginUrl, tokenUrl } = response.data;
            sessionStorage.setItem('oidcState', JSON.stringify({tokenUrl}));
            window.location.replace(loginUrl);
          } else {
            this.setState({ isAuthenticated: true, user }, () => {
                sessionStorage.setItem('authState', JSON.stringify(this.state));
            });
            return responseMe.data;
          }
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  callback = async (data) => {
    const { tokenUrl } = JSON.parse(sessionStorage.getItem('oidcState'));
    const state = data.get('state')
    const token = data.get('code');
    const tokenResponse = await axios.get(`${API_BASE_URL}${tokenUrl}?code=${token}&state=${state}`)
    const { accessToken, user } = tokenResponse.data;
    this.setState({ isAuthenticated: !!accessToken, user, token: accessToken }, () => {
      sessionStorage.setItem('authState', JSON.stringify(this.state));
    })
  }

  logout = () => {
    axios.get(`${API_BASE_URL}/api/auth/logout`);

    this.setState({ isAuthenticated: false, user: null, token: null, roles: null }, () => {
      sessionStorage.removeItem('authState');
    });
  };

  render() {
    const { isAuthenticated, user, token, roles } = this.state;
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated,
          user,
          token,
          roles,
          login: this.login,
          logout: this.logout,
          callback: this.callback,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
