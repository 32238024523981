import { Avatar, Box, Divider, FormControl, FormLabel, IconButton, Input, Link, List, ListItem, ListItemContent, ListItemDecorator, Modal, ModalClose, ModalDialog, Option, Select, Sheet, Table, Typography, iconButtonClasses } from "@mui/joy"
import { HiFilter, HiPencil, HiSearch, HiTrash, HiX } from "react-icons/hi"
import * as React from 'react';
import { HiMiniChevronDown, HiMiniMinus } from "react-icons/hi2";
import { Button, Checkbox, Badge } from "flowbite-react";
import { flattenKeys, flattenValues } from "./utils";
import { useSearchParams } from "react-router-dom";

function descendingComparator(a, b, orderBy, orderAttr) {
    
    const aValue = orderAttr ? a[orderBy][orderAttr] : a[orderBy];
    const bValue = orderAttr ? b[orderBy][orderAttr] : b[orderBy];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return bValue - aValue;
    }
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return bValue.localeCompare(aValue);
    }
    throw new Error(`Unsupported data type for sorting, valA: ${a}; valB: ${b}`);
  }

function getComparator(order, orderBy, orderAttr) {
    return order === 'desc' 
        ? (a, b) => descendingComparator(a, b, orderBy, orderAttr)
        : (a, b) => -descendingComparator(a, b, orderBy, orderAttr);
}

function switchOrder( order ) {
    if (order === 'asc') return 'desc';
    if (order === 'desc') return 'none';
    if (order === 'none') return 'asc';
}

//https://codesandbox.io/embed/zgpmxp?module=/App.tsx&fontsize=12
function OrderableCol({name, alias, setOrder, order}) {
    return (
        <Link
            key={name}
            underline="none"
            color={order === 'none' ? "" : "primary"}
            component="button"
            onClick={() => setOrder(name, switchOrder(order))}
            endDecorator={order === 'none' ? <HiMiniMinus /> : <HiMiniChevronDown />}
            sx={[
            {
                fontWeight: 'lg',
                '& svg': {
                transition: '0.2s',
                transform:
                    order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                },
            },
            order === 'desc'
                ? { '& svg': { transform: 'rotate(0deg)' } }
                : { '& svg': { transform: 'rotate(180deg)' } },
            ]}
        >
            {alias ? alias : name}
        </Link>
    )
}

export default function GenericTable({ data, columns, filters, onOrderChange, onDelete, onEdit, onClick, onFilter, onSearch, renderCard, orderableColumns=[], checkbox=false, additionalActions=[] }) {
  const [orderCol, setOrderCol] = React.useState({name: "", order: "none"});
  const [filter, setFilter] = React.useState([]);
  const [cols, setCols] = React.useState(columns);
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOrderChange = (name, order) => {
    const newOrder = switchOrder(order);
    setOrderCol({name, order: newOrder});
    if (onOrderChange) onOrderChange({name, order: newOrder});
  };

  const handleFilterChange = (value) => {
    let temp = filter;
    let updated = false;
    temp.forEach((element, index) => {
        if (element.column === value.column) {temp[index] = value; updated = true};
    });
    if (!updated) {temp.push(value)}
    setFilter(temp);
    forceUpdate();
  }

  function sorted(arr, sort=true, sortMethod, filter) {
    // Apply filters before sorting
    const filteredData = arr.filter(row => {
      return filter.every(f => {
        const rowValue = row[f.column];
        if (Array.isArray(f.value)) {
            if (f.value.length === 0) return true
            if (Array.isArray(rowValue)) {
                return rowValue.some(val => f.value.includes(flattenKeys(val)))
            }
            return f.value.includes(flattenKeys(rowValue));
        } else if (typeof f.value === 'number') {
            return rowValue <= f.value;
        }
        return false;
      });
    });
    return sort ? filteredData.sort(sortMethod) : filteredData;
  }

  const extractUniqueValues = (key, flatten=false) => {
    function onlyUnique(value, index, array) {
        if (typeof value === 'object' && value !== null) {
            // Если value - объект, используем flattenKeys для получения ключа
            const key = flattenKeys(value);
            // Проверяем, является ли этот ключ уникальным в массиве
            return array.findIndex(item => flattenKeys(item) === key) === index;
        }
        // Для примитивных типов оставляем оригинальную логику
        return array.indexOf(value) === index;
    }
      
    const values = new Set(data.map(row => row[key]));
    let difvalues = []
    Array.from(values).forEach(value => {
        function pushVal(val) {
            if (!difvalues.includes(val)) difvalues.push(val);
        }
        if (Array.isArray(value)) value.forEach(v => pushVal(v)) 
        else pushVal(value)
    });
    return difvalues
        .filter(onlyUnique)
        .sort((a, b) => flatten ? a-b : flattenValues(a).localeCompare(flattenValues(b)))
        .map(value => 
        {if (flatten) return flattenValues(value)
        else return <Option key={flattenKeys(value)} value={flattenKeys(value)}>{flattenValues(value)}</Option>
        }
    );
  };

  React.useEffect(() => {
    if (!columns) {
        const tcols = [];
        data.forEach(row => Object.getOwnPropertyNames(row).map(key => !tcols.includes(key) && tcols.push({name: key, alias: key})))
        setCols(tcols);
    } else setCols(columns)
  }, [data])

  const renderFilters = () => (
    <React.Fragment>
      {filters.map((filter) => (
        <FormControl size="sm" key={filter.column}>
          <FormLabel>{filter.label}</FormLabel>

          {filter.type && ["range"].includes(filter.type)
          ? (
            filter.type === "range" && <Input
                type="number"
                defaultValue={Math.max(...extractUniqueValues(filter.column, true))}
                onChange={(event) => {
                    if (!event.target.value) event.target.value = Math.max(...extractUniqueValues(filter.column, true));
                    handleFilterChange({column: filter.column, value: parseInt(event.target.value)});}}
                slotProps={{
                    input: {
                        min: Math.min(...extractUniqueValues(filter.column, true)) - 100,
                        max: Math.max(...extractUniqueValues(filter.column, true)) + 100,
                        step: 100
                    }
                }}
            />
          )
          : <Select
            multiple
            size="sm"
            placeholder={filter.placeholder}
            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
            onChange={(event, newValue) => handleFilterChange({column: filter.column, value: newValue})}
          >
            {extractUniqueValues(filter.column)}
          </Select>}
        </FormControl>
      ))}
    </React.Fragment>
  );

  return (
    data && 
    <React.Fragment>
        {filters && (<Sheet
            className="SearchAndFilters-mobile"
            sx={{ display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1 }}
        >
            {onSearch && <Input
                size="sm"
                placeholder="Поиск"
                value={searchParams.get('search')}
                onChange={(event, newValue) => onSearch(event.target.value)}
                startDecorator={<HiSearch />}
                endDecorator={
                <Button variant="soft" color="neutral"
                    onClick={() => onSearch("")}
                >
                    <HiX/>
                </Button>}
                sx={{ flexGrow: 1 }}
            />}
            <IconButton
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={() => setOpen(true)}
            >
                <HiFilter />
            </IconButton>
            <Modal open={open} onClose={() => setOpen(false)} sx={{marginTop: "var(--Header-height)"}}>
                <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                    <ModalClose />
                    <Typography id="filter-modal" level="h2">
                    Filters
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {renderFilters()}
                    <Button color="primary" onClick={() => {setOpen(false); onFilter ? onFilter(filter): console.log("No filter function for values:", filter)}}>
                        Submit
                    </Button>
                    </Sheet>
                </ModalDialog>
            </Modal>
        </Sheet>)}
        {filters && <Box
            className="SearchAndFilters-tabletUp"
            sx={{
            borderRadius: 'sm',
            py: 2,
            display: { xs: 'none', sm: 'flex' },
            flexWrap: 'wrap',
            gap: 1.5,
            '& > *': {
                minWidth: { xs: '120px', md: '160px' },
            },
            }}
        >
            {onSearch && <FormControl sx={{ flex: 1 }} size="sm">
                <FormLabel>Поиск</FormLabel>
                <Input 
                    size="sm" 
                    value={searchParams.get('search')}
                    placeholder="Поиск" startDecorator={<HiSearch />} 
                endDecorator={
                    <Button variant="soft" color="neutral"
                        onClick={() => onSearch("")}
                    >
                        <HiX/>
                    </Button>}
                onChange={(event, newValue) => onSearch(event.target.value)} />
            </FormControl>}
            {renderFilters()}
        </Box>}
        <Sheet
            className="OrderTableContainer"
            sx={{
            display: { xs: 'none', sm: 'initial' },
            width: '100%',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
            }}
        >
            <Table
                variant="plain"
                aria-labelledby="tableTitle"
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                    <tr className="bg-gray-50 bg-opacity-100">
                    {checkbox && 
                    (<th key="thcb" style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                        <Checkbox
                        size="sm"
                        indeterminate={
                            selected.length > 0 && selected.length !== data.length
                        }
                        checked={selected.length === data.length}
                        onChange={(event) => {
                            setSelected(
                            event.target.checked ? data.map((row) => row.id) : [],
                            );
                        }}
                        color={
                            selected.length > 0 || selected.length === data.length
                            ? 'primary'
                            : undefined
                        }
                        sx={{ verticalAlign: 'text-bottom' }}
                        />
                    </th>)
                    }
                    {
                        cols.map(col => <th key={col.name}  style={{ width: 120, padding: '12px 6px' }}>
                            {
                            orderableColumns.includes(col.name)
                                ? <OrderableCol name={col.name} alias={col.alias} setOrder={handleOrderChange} order={col.name === orderCol.name ? orderCol.order : 'none'}/>
                                : col.alias
                            }
                        </th>)
                    }
                    <th style={{ width: 140, padding: '12px 6px' }}> </th>
                    </tr>
                </thead>
                <tbody>
                    {sorted([...data], orderCol.order != 'none', getComparator(orderCol.order, orderCol.name, columns.find(v => v.name === orderCol.name)?.order), filter).map((row) => (
                    <tr key={row.id}>
                        {checkbox &&  (
                                <td style={{ textAlign: 'center', width: 120 }}>
                                    <Checkbox
                                        size="sm"
                                        checked={selected.includes(row.id)}
                                        color={selected.includes(row.id) ? 'primary' : undefined}
                                        onChange={(event) => {
                                        setSelected((ids) =>
                                            event.target.checked
                                            ? ids.concat(row.id)
                                            : ids.filter((itemId) => itemId !== row.id),
                                        );
                                        }}
                                        slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                                        sx={{ verticalAlign: 'text-bottom' }}
                                    />
                                </td>
                        )}
                        {cols.map(col => (
                            <td onClick={() => onClick ? onClick({col:col.name, id:row.id}) : {}}>
                                {Array.isArray(row[col.name])
                                ?   row[col.name].map(val => 
                                    <Badge
                                        key={flattenKeys(val)}
                                        color={val.color || "info"}
                                    >
                                        {flattenValues(val)}
                                    </Badge>)
                                
                                :   (col.badges
                                    ?   <Badge color={col.badges.find((value, index) => (row[col.name].id || row[col.name]) === (value?.id || flattenKeys(value)))?.color}>
                                        {col.badges.find((value, index) => (row[col.name].id || row[col.name]) === (value?.id || flattenKeys(value) ))?.name}
                                    </Badge>
                                    : <Typography level="body-xs">{col.mod ? col.mod(flattenKeys(row), row[col.name]) : flattenValues(row[col.name])}</Typography>
                                )}
                            </td>
                        ))}
                        <td className="flex-1">
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: 'fit-content' }}>
                            {onDelete && <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                onClick={() => onDelete(row.id)}
                            ><HiTrash /></IconButton>}
                            {onEdit && <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                onClick={() => onEdit(row)}
                            ><HiPencil /></IconButton>}
                            {additionalActions && additionalActions.map(action => 
                                <IconButton
                                    size="sm"
                                    variant="outlined"
                                    color="neutral"
                                    onClick={() => action.action(row)}
                                >{action.decorator}
                                </IconButton>
                                )}
                        </Box>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {sorted([...data], orderCol.order != 'none', getComparator(orderCol.order, orderCol.name, columns.find(v => v.name === orderCol.name)?.order), filter).map((row) => 
                (renderCard
                    ? renderCard(row) 
                    : (<List key={row.id} size="sm" sx={{ '--ListItem-paddingX': 0 }}>
                        <ListItem
                            key={row.id}
                            sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            }}
                        >
                            <ListItemContent sx={{ display: 'flex', gap: 1, alignItems: 'start', flexDirection: "column" }}>
                                {flattenValues(row.name) && <ListItemDecorator>
                                    <Avatar size="sm">{Array.from(flattenValues(row.name))[0].toUpperCase()}</Avatar>
                                </ListItemDecorator>}
                                {cols.map(col => (
                                    Array.isArray(row[col.name])
                                    ?   row[col.name].map(val => 
                                        <Badge
                                            key={flattenKeys(val)}
                                            color={val.color || "info"}
                                        >
                                            {flattenValues(val)}
                                        </Badge>)
                                    
                                    : <Typography level="body-xs" gutterBottom>
                                        {col.mod ? col.mod(flattenKeys(row), flattenValues(row[col.name])) : flattenValues(row[col.name])}
                                    </Typography>
                                ))}
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => onDelete ? onDelete(row.id) : {}}
                                    ><HiTrash /></IconButton>
                                </Box>
                            </ListItemContent>
                        </ListItem>
                    </List>)
                )
            )}
        </Box>
    </React.Fragment>
  );
}