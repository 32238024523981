import React, { useEffect, useRef, useState } from "react";
import { Badge, Card, ListItem, Modal, ModalBody, ModalHeader, Select, TextInput } from "flowbite-react";
import { HiArrowRight, HiArrowSmRight, HiCheck, HiChevronDown, HiChevronRight, HiPlus, HiQuestionMarkCircle, HiX } from "react-icons/hi";
import { HiArrowRightEndOnRectangle } from "react-icons/hi2";
import { Box, Menu, MenuButton, MenuItem, Dropdown, Input, IconButton, Button, ListDivider, ListItemButton, List, Tooltip } from "@mui/joy";
import { flattenKeys, flattenValues } from "./utils";
import useDebounce from "../hooks/useDebounce";

// EditableField component: Renders a read-only value that can be clicked to edit
const EditableField = ({ value, renderEditMode, renderStaticMode, handleChange, ...props }) => {
  const [isEditing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(()=>{setCurrentValue(value)}, [value])

  return (
    <div {...props} onClick={() => setEditing(true)} style={{ cursor: "pointer" }}>
      {isEditing ? (
        <div>
          {renderEditMode(currentValue, setCurrentValue)}
        </div>
      ) : (
        <div>
          {renderStaticMode ? renderStaticMode(currentValue) : renderEditMode(currentValue, setCurrentValue) }
        </div>
      )}
    </div>
  );
};

// // Example usage for editable text field
// const EditableTextField = ({}) => {
//   return (
//     <EasyEdit
//       type="text"
//       value={value}
//       onSave={save}
//       onCancel={cancel}
//       saveButtonLabel="Сохранить"
//       cancelButtonLabel="Отменить"
//       attributes={{ name: "awesome-input", id: 1}}
//       instructions="Введите текст"
//     />
//   );
// }

// Example usage for editable text field
const EditableTextField = ({ value, onApply, labelComponent, blocked=false, numeric, className="", placeholder, multiline, activeDefault, onCancel}) => {
  const Field = (props) => {
    const [ isActive, setIsActive ] = useState(!!activeDefault);
    const fieldRef = useRef(null);
    const defaultValue = useRef(value);

    // const handleInput = (event) => {
    //   if (props.onChange) {
    //     props.onChange(event.target.innerHTML);
    //   }
    // }; 

    const handleCancel = () => {
      console.log(defaultValue.current)
      fieldRef.current.innerText = defaultValue.current || placeholder || `Введите ${numeric ? 'число' : 'значение' }`;
      if (props.onChange && (props.value && fieldRef.current.innerText !== (placeholder || `Введите ${numeric ? 'число' : 'значение' }`))) {
        props.onChange(defaultValue.current);
      }
      setIsActive(false);
      if (onCancel) onCancel();
    }

    const onKeyPress = (e) => {
      var x = e.charCode || e.keyCode;
      if (numeric && (isNaN(String.fromCharCode(e.which)) && x!=46 && x!=8 || x===32 || x===13 || (x===46 && e.target.innerText.includes('.')))) e.preventDefault();
      const value = e.target.innerText;
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        e.target.blur();
        setIsActive(false);
        onApply(value);
      }
    };

    useEffect(()=>{
      fieldRef.current.innerText = props.value || placeholder || `Введите ${numeric ? 'число' : 'значение' }`;
    }, [props.value])

    useEffect(()=>{
      activeDefault && fieldRef.current && fieldRef.current.focus()
    }, [])

    return (
        <Box className={`relative w-full p-1 rounded-md ${isActive ? 'bg-gray-300 focus:bg-transparent ' : 'hover:bg-gray-300 bg-transparent'} w-full ${props.className}`}>
          <Box
            ref={fieldRef}
            class="h-full "
            style={{
              whiteSpace: "pre-wrap",
            }}
            onClick={()=>{
              setIsActive(true);
              if (!props.value && fieldRef.current.innerText === (placeholder || `Введите ${numeric ? 'число' : 'значение' }`)) fieldRef.current.innerText = "";
            }}
            // onInput={handleInput}
            onKeyDown={onKeyPress}
            onBlur={() => setIsActive(true)}
            // dangerouslySetInnerHTML={{ __html: activeValue.current }}
            suppressContentEditableWarning={true}
            {...props}
          />
          {isActive && !multiline &&
          <Box className="flex flex-row absolute top-2 right-2 gap-2">
            <IconButton onClick={() => handleCancel()} size="xs">
              <HiX/>
            </IconButton>
            <IconButton onClick={() => onApply(fieldRef.current?.innerText)} disabled={
              !props.value || fieldRef.current?.innerText === (placeholder || `Введите ${numeric ? 'число' : 'значение' }`)
            } size="xs">
              <HiCheck/>
            </IconButton>
            
          </Box>}
          {isActive && multiline &&
          <Box className="flex flex-row mt-2 gap-2">
            <Button onClick={() => handleCancel()} size="sm" variant="outlined">
              Отменить
            </Button>
            <Button onClick={() => onApply(fieldRef.current?.innerText)} disabled={
              !props.value || fieldRef.current?.innerText === (placeholder || `Введите ${numeric ? 'число' : 'значение' }`)
            } size="sm">
              Сохранить
            </Button>
            
          </Box>}
        </Box>
        

    )
  }

  function renderStatic (currentValue) {
    return (
      <Box className="w-full grid grid-cols-5 items-baseline">
        {labelComponent && <Box Box className="col-span-2 md:col-span-1">
          {labelComponent}
        </Box>}
        <Box className={labelComponent ? "col-span-3 md:col-span-4 px-4" : "col-span-5 px-4"}>
          <Field  
            value={currentValue}
            onChange={() => {}}
            className={className}
          />
        </Box>
      </Box>
    )
  };

  function renderDynamic(currentValue, setCurrentValue) {
    return (
      <Box className="w-full grid grid-cols-5 items-baseline">
        {labelComponent && <Box Box className="col-span-2 md:col-span-1">
          {labelComponent}
        </Box>}
        <Box className={labelComponent ? "col-span-3 md:col-span-4 px-4" : "col-span-5 px-4"}>
          <Field
            contentEditable
            value={currentValue}
            onChange={setCurrentValue}
            className={className}
          />
        </Box>
      </Box>
    )
  }
  return (

    <EditableField
      value={value}
      renderEditMode={blocked ? renderStatic : renderDynamic}
      renderStaticMode={renderStatic}
    />
  );
};

// const EditableTextField = ({ value, onApply, labelComponent, blocked=false, numeric, className="", placeholder, multiline }) => {
//   const [defaultValue, setDefaultValue] = useState(value);
//   const [isActive, setIsActive] = useState(false);
//   const fieldRef = useRef();

//   useEffect(()=>{
//     setDefaultValue(value);
//     fieldRef.current.innerText = value || placeholder || "Введите значение";
//   }, [value])

//   useEffect(()=>{
//     console.log('isActive: ', isActive)
//   }, [isActive])

//   const handleSave = (e) => {
//     if (fieldRef.current.innerText != defaultValue) {
//       setIsActive(false);
//       onApply(fieldRef.current.innerText);
//     }
//   }

//   const handleCancel = (e) => {
//     if (fieldRef.current.innerText != defaultValue) {
//       fieldRef.current.innerText = defaultValue || placeholder || "Введите значение";
//       setIsActive(false);
//       console.log('isActive: ', isActive);
//     }
//   }
 
//   const handleInput = (event) => {
//     const { innerText } = event.target;
//     if (fieldRef.current.innerText !== innerText) {
//       fieldRef.current.innerText = innerText;
//     }
//   }; 

//   const onKeyPress = (e) => {
//     var x = e.charCode || e.keyCode;
//     if (numeric && (isNaN(String.fromCharCode(e.which)) && x!=46 && x!=8) || x===32 || x===13 || (x===46 && e.target.innerText.includes('.'))) e.preventDefault();
//     const value = e.target.innerText;
//     if (e.key === "Enter" && !e.shiftKey) {
//       e.preventDefault();
//       setIsActive(false);
//       onApply(value);
//     }
//   };

//   return (
//     <Box className="w-full grid grid-cols-5 items-baseline">
//         {labelComponent && <Box Box className="col-span-2 md:col-span-1">
//           {labelComponent}
//         </Box>}
//         <Box 
//           onClick={()=>{!blocked && setIsActive(true)}}
//           className={labelComponent ? "col-span-3 md:col-span-4 px-4" : "col-span-5 px-4"}>
//           <Box className={`relative w-full p-1 rounded-md ${isActive ? 'bg-gray-300 focus:bg-transparent ' : 'hover:bg-gray-300 bg-transparent'} w-full ${className}`}>
//             <Box
//               ref={fieldRef}
//               contentEditable={isActive && !blocked}
//               class="h-full "
//               style={{
//                 whiteSpace: "pre-wrap",
//               }}
//               onInput={handleInput}
//               onKeyDown={onKeyPress}
//               onBlur={() => setIsActive(true)}
//               suppressContentEditableWarning={true}
//             />
//             {isActive && !multiline
//             && <Box className="absolute top-1.5 right-2">
//               <IconButton onClick={handleCancel} size="xs">
//                 <HiX/>
//               </IconButton>
//               <IconButton onClick={handleSave} size="xs">
//                 <HiCheck/>
//               </IconButton>
//             </Box>}
//             {isActive && multiline
//             && <Box className="right-2">
//               <Button onClick={handleSave} size="xs">
//                 Сохранить
//               </Button>
//             </Box>}
//           </Box>
//         </Box>
//       </Box>
//   );
// };

// Example usage for editable badge field
const EditableBadgeField = ({ value, onApply, badges, labelComponent, blocked=false }) => {
  function renderDynamic(currentValue, setCurrentValue) {
    return (
      <Box className="w-full grid grid-cols-5 items-center">
        {labelComponent && <Box Box className="col-span-2 md:col-span-1">
            {labelComponent}
        </Box>}
          {badges && <div className={labelComponent ? "col-span-3 md:col-span-4" : "col-span-5"}>
            <Dropdown>
              <MenuButton
                variant="plain"
              >
                <Badge 
                className="w-full h-full"
                key={badges?.find((b) => b.id === currentValue)?.id}
                color={badges?.find((b) => b.id === currentValue)?.color}
                >
                  <div className="flex flex-row place-items-center">
                    {badges?.find((b) => b.id === currentValue)?.name}
                    <HiChevronDown className="ml-2" size={14}/>
                  </div>
                </Badge>
              </MenuButton>
              <Menu>
              {badges.map((option) => (
                <MenuItem key={option.id} value={option.id} onClick={() => {setCurrentValue(option.id); onApply(option.id)}}><Badge 
                  key={option.id} 
                  color={option.color}
                  >{option.name}</Badge></MenuItem>
              ))}
              </Menu>
            </Dropdown>
          </div>}
        </Box>
    )
  };

  function renderStatic(currentValue) {
    return (
      <Box className="w-full grid grid-cols-5 items-center">
          {labelComponent && <Box Box className="col-span-2 md:col-span-1">
            {labelComponent}
          </Box>}
          {badges && <div className={labelComponent ? "col-span-3 md:col-span-4 w-fit px-4" : "col-span-5 w-fit px-4"}>
              <Badge 
                color={badges?.find((b) => b.id === currentValue)?.color}
              >
                <div className="flex flex-row place-items-center">
                  {badges?.find((b) => b.id === currentValue)?.name}
                </div>
              </Badge>
            </div>}
        </Box>
    )
  }
  return (
    <EditableField
      value={value}
      className="relative"
      renderEditMode={blocked ? renderStatic : renderDynamic}
    />
  );
};

// Example usage for editable link field
const EditableLinkField = ({ value, labelComponent, onApply, onSearch, onCreate, blocked=false }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  const [newValue, setNewValue] = useState(null);

  const debaunceRequest = useDebounce(async (r, q) => {
    setSearchLoader(true);
    let result = await onSearch(q);
    result.map((res) => ({
      id: flattenKeys(res),
      value: flattenValues(res),
    }));
    setSearchResults(r.concat(result));
    setSearchLoader(false);
  }, 500)

  const handleCreate = async (value) => {
    const newData = await onCreate(flattenValues(value));
    handleSelect({id: flattenKeys(newData), name: flattenValues(newData)});
  }

  const handleSearch = async (q) => {
    setQuery(q);
    if (q.length > 2) {
      let results = [];
      if (onCreate) {
        results.push({ id: null, value: q });
        setSearchResults(results)
      }
      debaunceRequest(results, q);
    }
    else {
      setSearchResults([]);
    }
  };

  const handleSelect = (val) => {
    if (val != newValue) {
      setNewValue(val);
      setQuery("");
      setSearchResults([]);
    }
  }

  const deleteValue = () => {
    setNewValue({});
  }

  useEffect(() => {
    setNewValue(value);
  }, [value])

  return (
    <Box className="w-full grid grid-cols-5 items-center">
          {labelComponent && <Box Box className="col-span-2 md:col-span-1">
            {labelComponent}
          </Box>}
          <Box className={labelComponent ? "col-span-3 md:col-span-4 px-4 gap-2" : "col-span-5 px-4 gap-2"} sx={{
                display: isOpen ? "none" : "Flex"
              }} onClick={blocked ? () => {} : () => setIsOpen(true)}>
            {newValue ?
              <Badge 
                className="w-fit h-full"
                color="info"
              >
                <div className="flex flex-row place-items-center">
                  {flattenValues(newValue)}
                </div>
              </Badge> : !blocked && <IconButton size="sm" onClick={blocked ? () => {} : () => setIsOpen(true)} variant="soft"><HiPlus /></IconButton>
            }
          </Box>
          <Box
            className="col-span-5 flex-col gap-2"
              sx={{
                display: isOpen ? "flex" : "none"
              }}
            >
              <Box className="w-full flex flex-row">
                <Input
                  value={query}
                  size="sm"
                  className="w-full"
                  placeholder="Введите текст для поиска"
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {query.length > 1 && <IconButton
                  size="sm"
                  variant="plain"
                  onClick={() => {setQuery(""); handleSearch("")}}
                >
                    <HiX/>
                </IconButton>}
              </Box>
              {searchResults.length > 0 && (
                  <List className="w-full flex flex-row">
                    {searchResults.map(res => 
                      <ListItem>
                        <ListItemButton size="xs" onClick={() => !res.id && onCreate ? handleCreate(res) : handleSelect(res)}>
                          {res.id ? flattenValues(res) : `${flattenValues(res)} - добавить значение`}
                        </ListItemButton>
                      </ListItem>
                    )}
                    <ListDivider />
                  </List>
              )}
              <Box className="w-full flex flex-row gap-2">
                {newValue &&
                  <Badge 
                    className="w-fit h-full"
                    color="info"
                  >
                    <div className="flex flex-row place-items-center gap-4">
                      {flattenValues(newValue)}
                      <IconButton
                        size="xs"
                        variant="plain"
                        onClick={() => deleteValue(flattenKeys(newValue))}
                      >
                          <HiX/>
                      </IconButton>
                    </div>
                  </Badge>
                }
              </Box>
              <Box className="w-full flex flex-row gap-4">
                <Button variant="soft" size="sm" onClick={
                  () => {setNewValue(value); setIsOpen(false)}
                }>
                  Отменить
                </Button>
                <Button variant="soft" disabled={value===newValue} size="sm" onClick={() => {onApply(newValue); setIsOpen(false)}}>
                  Сохранить
                </Button>
              </Box>
          </Box>
    </Box>
  );
};

// Example usage for editable link field
const EditableMultiLinkField = ({ value, labelComponent, onApply, onSearch, searchInfo, onCreate, colored, blocked=false }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  const [newValues, setNewValues] = useState([]);

  const debaunceRequest = useDebounce(async (r, q) => {
    setSearchLoader(true);
    let result = await onSearch(q);
    result.map((res) => ({
      id: flattenKeys(res),
      value: flattenValues(res),
    }));
    setSearchLoader(false);
    setSearchResults(r.concat(result));
  }, 500)

  const handleSearch = async (q) => {
    setQuery(q);
    if (q.length > 2) {
      let results = [];
      if (onCreate) {
        results.push({ id: null, value: q });
        setSearchResults(results)
      }
      debaunceRequest(results, q);
    }
    else {
      setSearchResults([]);
    }
  };

  const handleCreate = async (value) => {
    const newData = await onCreate(flattenValues(value));
    handleSelect({id: flattenKeys(newData), name: flattenValues(newData)});
  }

  const handleSelect = (val) => {
    let temp_values = newValues;
    if (temp_values.filter(v => val === v).length === 0) {
      temp_values.push(val);
      setNewValues(temp_values);
      setQuery("");
      setSearchResults([]);
    }
  }

  const deleteValue = (id) => {
    setNewValues(newValues.filter(val => flattenKeys(val) != id));
  }

  useEffect(() => {
    setNewValues(value ? value.slice() : []);
  }, [value])

  return (
    <Box className="w-full grid grid-cols-5 items-center">
          {labelComponent && <Box Box className="col-span-2 md:col-span-1">
            {labelComponent}
          </Box>}
          <Box className={labelComponent ? "col-span-3 md:col-span-4 px-4 gap-2 flex-col" : "col-span-5 px-4 gap-2 flex-col"} sx={{
                display: isOpen ? "none" : "Flex"
              }} onClick={blocked ? () => {} : () => setIsOpen(true)}>
            {newValues && newValues.length > 0 ? newValues.map((val) => (
              <Badge 
                className="w-fit h-full"
                color={colored ? colored(val) : "info"}
              >
                <div className="flex flex-row place-items-center">
                  {flattenValues(val)}
                </div>
              </Badge>
            )) : !blocked && <IconButton size="sm" onClick={blocked ? () => {} : () => setIsOpen(true)} variant="soft"><HiPlus /></IconButton>}
            
          </Box>
          <Box
            className="col-span-5 flex-col gap-2"
              sx={{
                display: isOpen ? "flex" : "none"
              }}
            >
              <Box className="w-full flex flex-row">
                {searchInfo && <Tooltip title={searchInfo} variant="plain">
                  <IconButton variant="plain"><HiQuestionMarkCircle /></IconButton>
                </Tooltip>}
                <Input
                  value={query}
                  size="sm"
                  className="w-full"
                  placeholder="Введите текст для поиска"
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {query.length > 1 && <IconButton
                  size="sm"
                  variant="plain"
                  onClick={() => {setQuery(""); handleSearch("")}}
                >
                    <HiX/>
                </IconButton>}
              </Box>
              {searchResults.length > 0 && (
                  <List className="w-full flex flex-row">
                    {searchResults.map(res => 
                      <ListItem>
                        <ListItemButton size="xs" onClick={() => !res.id && onCreate ? handleCreate(res) : handleSelect(res)}>
                          {colored 
                          ? <Badge color={colored(res)}>{res.id ? flattenValues(res) : `${flattenValues(res)} - добавить значение`}</Badge>
                          : <div>
                            {res.id ? flattenValues(res) : `${flattenValues(res)} - добавить значение`}
                          </div>}
                        </ListItemButton>
                      </ListItem>
                    )}
                    <ListDivider />
                  </List>
              )}
              <Box className="w-full flex flex-col gap-1">
                {newValues && newValues.map((val) => (

                  <Badge 
                    className="w-fit h-full"
                    color={colored ? colored(val) : "info"}
                  >
                    <div className="flex flex-row place-items-center gap-4">
                      {flattenValues(val)}
                      <IconButton
                        size="xs"
                        variant="plain"
                        onClick={() => deleteValue(flattenKeys(val))}
                      >
                          <HiX/>
                      </IconButton>
                    </div>
                  </Badge>
                ))}
              </Box>
              <Box className="w-full flex flex-row gap-4">
                <Button variant="soft" size="sm" onClick={
                  () => {setNewValues(value); setIsOpen(false)}
                }>
                  Отменить
                </Button>
                <Button variant="soft" disabled={JSON.stringify(value)===JSON.stringify(newValues)} size="sm" onClick={() => {onApply(newValues); setIsOpen(false)}}>
                  Сохранить
                </Button>
              </Box>
          </Box>
    </Box>
  );
};

export {
  EditableField,
  EditableTextField,
  EditableBadgeField,
  EditableLinkField,
  EditableMultiLinkField,
};
