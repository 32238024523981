import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Avatar, Box, Divider, FormControl, FormLabel, IconButton, Input, Link, List, ListItem, ListItemContent, ListItemDecorator, Modal, ModalClose, ModalDialog, Option, Select, Sheet, Table, Typography, iconButtonClasses } from "@mui/joy"
import { HiFilter, HiPencil, HiSearch, HiTrash, HiX } from "react-icons/hi"
import * as React from 'react';
import { HiMiniChevronDown, HiMiniMinus } from "react-icons/hi2";
import { Button, Checkbox, Badge } from "flowbite-react";
import { flattenKeys, flattenValues } from "./utils";
import { useSearchParams } from "react-router-dom";

const KanbanCol = ({ title, color, cards, index }) => {
  return (
    <Box
      className="bg-white rounded px-2 py-2 h-fit"
      sx={{ minHeight: { md: "100%" } }}
    >
      <div class="flex flex-row justify-between items-center mb-2 mx-1">
        <div class="flex items-center">
          <Badge color={color}>{title}</Badge>
          <p class="pl-1 text-gray-400 text-sm">{cards?.length}</p>
        </div>
        {/* <div class="flex items-center text-gray-300">
          <p class="mr-2 text-2xl">---</p>
          <p class="text-2xl">+</p>
        </div> */}
      </div>
      <Droppable key={index} droppableId={index.toString()}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="min-h-80 h-fit"
          >
            {provided.placeholder}
            {cards.map((card, i) => (
              <Draggable
                key={i}
                draggableId={card.id.toString()}
                index={card.id}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {card.child}
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </Box>
  );
};

const Kanban = ({ columns, onDragEnd, filters, onFilter, onSearch }) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1200);
  const [filter, setFilter] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const handleFilterChange = (value) => {
    let temp = filter;
    let updated = false;
    temp.forEach((element, index) => {
        if (element.column === value.column) {temp[index] = value; updated = true};
    });
    if (!updated) {temp.push(value)}
    setFilter(temp);
    forceUpdate();
  }

  const getFiltred = (arr) => {
    const filteredData = arr.filter(row => {
      return filter.every(f => {
        const rowValue = row.child.props.data[f.column];
        if (Array.isArray(f.value)) {
            if (f.value.length === 0) return true
            if (Array.isArray(rowValue)) {
                return rowValue.some(val => f.value.includes(flattenKeys(val)))
            }
            return f.value.includes(flattenKeys(rowValue));
        } else if (typeof f.value === 'number') {
            return rowValue <= f.value;
        }
        return false;
      });
    });
    return filteredData;
  }
  const extractUniqueValues = (key, flatten=false) => {
    function onlyUnique(value, index, array) {
        if (typeof value === 'object' && value !== null) {
            // Если value - объект, используем flattenKeys для получения ключа
            const key = flattenKeys(value);
            // Проверяем, является ли этот ключ уникальным в массиве
            return array.findIndex(item => flattenKeys(item) === key) === index;
        }
        // Для примитивных типов оставляем оригинальную логику
        return array.indexOf(value) === index;
    }
    
    const data = [];
    columns.forEach(col => col.cards.forEach(card => data.push(card.child.props.data[key])));
    const values = new Set(data);
    let difvalues = []
    Array.from(values).forEach(value => {
        function pushVal(val) {
            if (!difvalues.includes(val)) difvalues.push(val);
        }
        if (Array.isArray(value)) value.forEach(v => pushVal(v)) 
        else pushVal(value)
    });
    return difvalues.filter(onlyUnique).map(value => 
        {if (flatten) return flattenValues(value)
        else return <Option key={flattenKeys(value)} value={flattenKeys(value)}>{flattenValues(value)}</Option>
        }
    );
  };

  const renderFilters = () => (
    <React.Fragment>
      {filters.map((filter) => (
        <FormControl size="sm" key={filter.column}>
          <FormLabel>{filter.label}</FormLabel>

          {filter.type && ["range"].includes(filter.type)
          ? (
            filter.type === "range" && <Input
                type="number"
                defaultValue={Math.max(...extractUniqueValues(filter.column, true))}
                onChange={(event) => {
                    if (!event.target.value) event.target.value = Math.max(...extractUniqueValues(filter.column, true));
                    handleFilterChange({column: filter.column, value: parseInt(event.target.value)});}}
                slotProps={{
                    input: {
                        min: Math.min(...extractUniqueValues(filter.column, true)) - 100,
                        max: Math.max(...extractUniqueValues(filter.column, true)) + 100,
                        step: 100
                    }
                }}
            />
          )
          : <Select
            multiple
            size="sm"
            placeholder={filter.placeholder}
            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
            onChange={(event, newValue) => handleFilterChange({column: filter.column, value: newValue})}
          >
            {extractUniqueValues(filter.column)}
          </Select>}
        </FormControl>
      ))}
    </React.Fragment>
  );

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1024;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  return (
    <>
      {filters && (
        <Sheet
            className="SearchAndFilters-mobile"
            sx={{ display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1 }}
        >
            {onSearch && <Input
                size="sm"
                placeholder="Поиск"
                value={searchParams.get('search')}
                onChange={(event, newValue) => onSearch(event.target.value)}
                startDecorator={<HiSearch />}
                endDecorator={
                <Button variant="soft" color="neutral"
                    onClick={() => onSearch("")}
                >
                    <HiX/>
                </Button>}
                sx={{ flexGrow: 1 }}
            />}
            <IconButton
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={() => setOpen(true)}
            >
                <HiFilter />
            </IconButton>
            <Modal open={open} onClose={() => setOpen(false)} sx={{marginTop: "var(--Header-height)"}}>
                <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                    <ModalClose />
                    <Typography id="filter-modal" level="h2">
                    Filters
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {renderFilters()}
                    <Button color="primary" onClick={() => {setOpen(false); onFilter ? onFilter(filter): console.log("No filter function for values:", filter)}}>
                        Submit
                    </Button>
                    </Sheet>
                </ModalDialog>
            </Modal>
        </Sheet>)}
      {filters && 
        <Box
            className="SearchAndFilters-tabletUp"
            sx={{
            borderRadius: 'sm',
            py: 2,
            display: { xs: 'none', sm: 'flex' },
            flexWrap: 'wrap',
            gap: 1.5,
            '& > *': {
                minWidth: { xs: '120px', md: '160px' },
            },
            }}
        >
            {onSearch && <FormControl sx={{ flex: 1 }} size="sm">
                <FormLabel>Поиск</FormLabel>
                <Input 
                    size="sm" 
                    value={searchParams.get('search')}
                    placeholder="Поиск" startDecorator={<HiSearch />} 
                endDecorator={
                    <Button variant="soft" color="neutral"
                        onClick={() => onSearch("")}
                    >
                        <HiX/>
                    </Button>}
                onChange={(event, newValue) => onSearch(event.target.value)} />
            </FormControl>}
            {renderFilters()}
        </Box>}
      <DragDropContext
        onDragEnd={(r) => onDragEnd(r.draggableId, r.destination.droppableId)}
      >
        <Box
          className="grid gap-2 h-full"
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr));",
              md: `repeat(${!isMobile ? columns.length : 1}, minmax(0, 1fr));`,
            },
          }}
        >
          {columns.map((col, index) => {
            return (
              <KanbanCol
                key={index}
                title={col.title}
                color={col.color}
                cards={filter ? getFiltred(col.cards) : col.cards}
                index={col.id}
              />
            );
          })}
        </Box>
      </DragDropContext>
    </>
    
  );
};

export default Kanban;
