import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { AuthConsumer, AuthProvider } from "./context/AuthContext";
import LoginPage from "./pages/LoginPage";
import UserAdminPage from "./pages/UserAdminPage";
import PartnerAdminPage from "./pages/PartnerAdminPage";
import DashboardPage from "./pages/DashboardPage";
import VacancyListPage from "./pages/VacancyListPage";
import SettingsPage from "./pages/SettingsPage";
import { LoadingProvider } from "./context/LoadingContext";
import VacancyList from "./pages/VacancyPublic";
import RecruitmentPage from "./pages/RecruitmentPage";
import ProcessingPage from "./pages/ProcessingPage";
import Layout from "./components/layout";
import { AuthCallback } from "./components/AuthCallback";
import axios from "axios";
import { Provider } from 'react-redux'
import store from "./store";

class App extends Component {
  render() {
    axios.defaults.withCredentials = true;
    return (
      <Provider store={store}>
        <Router>
          <AuthProvider>
            <AuthConsumer>
              {({ isAuthenticated, roles }) => (
                <LoadingProvider>
                  {isAuthenticated ? (
                    <Layout>
                      <Routes>
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route path="/users" element={<UserAdminPage />} />
                        <Route
                          path="/partners"
                          element={<PartnerAdminPage />}
                        />
                        <Route
                          path="/vacancies"
                          element={<VacancyListPage />}
                        />
                        <Route
                          path="/recruitment"
                          element={<RecruitmentPage />}
                        />
                        <Route
                          path="/processing"
                          element={<ProcessingPage />}
                        />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/jobs" element={<VacancyList />} />
                        <Route
                          path="*"
                          element={<Navigate to="/dashboard" replace />}
                        />
                      </Routes>  
                    </Layout>
                    
                  ) : (
                    <Routes>
                      <Route path="/login" element={<LoginPage />} />
                      <Route path="/jobs" element={<VacancyList />} />
                      <Route path="/oidc-callback" element={<AuthCallback />} />
                      <Route
                        path="*"
                        element={<Navigate to="/login" replace />}
                      />
                    </Routes>
                  )}
                </LoadingProvider>
              )}
            </AuthConsumer>
          </AuthProvider>
        </Router>
      </Provider>
    );
  }
}

export default App;
