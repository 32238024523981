import { connect, useDispatch } from "react-redux";
import {
    Badge,
    Button,
    Label,
    Modal,
    Select,
    TextInput,
} from "flowbite-react";
import { HiPlus } from "react-icons/hi";
import { Box, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import GenericTable from "../components/tableAdmin";
import {
    EditableBadgeField,
    EditableTextField,
} from "../components/editableField";
import { statusesActions } from "../slices/statuses";

const StatusSettings = (props) => {
    const dispatch = useDispatch();
    
    const [statusModalOpen, setStatusModalOpen] = useState(false);

    const handleSubmitStatus = async (event) => {
        event.preventDefault();
        dispatch(props.statusesActions.create({data: {
            name: event.target.statusName.value,
            color: event.target.statusColors.value,
            weight: event.target.statusWeight.value,
            relation: event.target.statusRel.value,
        }}))
       
        setStatusModalOpen(false);
    };
    const handleUpdateStatus = ({ id, column, value }) => {
        dispatch(props.statusesActions.update({id, data: {[column]: value}}))
    };

    const handleDeleteStatus = (id) => {
        dispatch(props.statusesActions.drop({id}))
    };

    useEffect(()=>{
        if ((!props.statuses.entities || props.statuses.entities.length === 0) && !props.statuses.loading) dispatch(props.statusesActions.getAll());
    }, [])

    return (
        <Box>
            <Box>
                <Typography level="h4" component="h1">
                    Статусы
                </Typography>
                <Modal
                    onClose={() => setStatusModalOpen(false)}
                    show={statusModalOpen}
                >
                    <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                        <strong>Создать статус</strong>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            onSubmit={handleSubmitStatus}
                            className="grid grid-cols-1 gap-6 sm:grid-cols-2"
                        >
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="statusName"
                                        value="Название"
                                    />
                                </div>
                                <TextInput
                                    id="statusName"
                                    type="text"
                                    placeholder="Статус"
                                    required
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="statusColors"
                                        value="Цвет UI"
                                    />
                                </div>
                                <Select
                                    id="statusColors"
                                    required
                                    defaultValue="info"
                                >
                                    <option value="info">
                                        <Badge color="info">info</Badge>
                                    </option>
                                    <option value="gray">
                                        <Badge color="gray">gray</Badge>
                                    </option>
                                    <option value="failure">
                                        <Badge color="failure">
                                            failure
                                        </Badge>
                                    </option>
                                    <option value="success">
                                        <Badge color="success">
                                            success
                                        </Badge>
                                    </option>
                                    <option value="warning">
                                        <Badge color="warning">
                                            warning
                                        </Badge>
                                    </option>
                                    <option value="indigo">
                                        <Badge color="indigo">indigo</Badge>
                                    </option>
                                    <option value="purple">
                                        <Badge color="purple">purple</Badge>
                                    </option>
                                    <option value="pink">
                                        <Badge color="pink">pink</Badge>
                                    </option>
                                </Select>
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="statusWeight"
                                        value="Вес"
                                    />
                                </div>
                                <TextInput
                                    id="statusWeight"
                                    type="number"
                                    placeholder="0"
                                    required
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="statusRel"
                                        value="Связанные объекты БД"
                                    />
                                </div>
                                <TextInput
                                    id="statusRel"
                                    type="text"
                                    placeholder="relation"
                                />
                            </div>
                            <Button type="submit">Создать</Button>
                        </form>
                    </Modal.Body>
                </Modal>
                <GenericTable
                    data={props.statuses.entities}
                    orderableColumns={["name", "weight"]}
                    columns={[
                        { name: "id", alias: "ID" },
                        {
                            name: "name",
                            alias: "Название",
                            mod: (obj_id, value) => (
                                <EditableTextField
                                    value={value}
                                    onApply={(v) =>
                                        handleUpdateStatus({
                                            id: obj_id,
                                            column: "name",
                                            value: v,
                                        })
                                    }
                                />
                            ),
                        },
                        {
                            name: "color",
                            alias: "Цвет UI",
                            mod: (obj_id, value) => (
                                <EditableBadgeField
                                    value={value}
                                    badges={[
                                        {
                                            id: "failure",
                                            color: "failure",
                                            name: "failure",
                                        },
                                        {
                                            id: "warning",
                                            color: "warning",
                                            name: "warning",
                                        },
                                        {
                                            id: "info",
                                            color: "info",
                                            name: "info",
                                        },
                                        {
                                            id: "gray",
                                            color: "gray",
                                            name: "gray",
                                        },
                                        {
                                            id: "success",
                                            color: "success",
                                            name: "success",
                                        },
                                        {
                                            id: "indigo",
                                            color: "indigo",
                                            name: "indigo",
                                        },
                                        {
                                            id: "purple",
                                            color: "purple",
                                            name: "purple",
                                        },
                                        {
                                            id: "pink",
                                            color: "pink",
                                            name: "pink",
                                        },
                                    ]}
                                    onApply={(v) =>
                                        handleUpdateStatus({
                                            id: obj_id,
                                            column: "color",
                                            value: v,
                                        })
                                    }
                                />
                            ),
                        },
                        {
                            name: "weight",
                            alias: "Вес",
                            mod: (obj_id, value) => (
                                <EditableTextField
                                    value={value}
                                    numeric
                                    onApply={(v) =>
                                        handleUpdateStatus({
                                            id: obj_id,
                                            column: "weight",
                                            value: v,
                                        })
                                    }
                                />
                            ),
                        },
                        {
                            name: "relation",
                            alias: "Связь",
                            mod: (obj_id, value) => (
                                <EditableTextField
                                    value={value}
                                    onApply={(v) =>
                                        handleUpdateStatus({
                                            id: obj_id,
                                            column: "relation",
                                            value: v,
                                        })
                                    }
                                />
                            ),
                        },
                    ]}
                    onDelete={handleDeleteStatus}
                    filters={[
                        {
                            label: "Связь",
                            column: "relation",
                            placeholder: "Связь",
                        },
                    ]}
                />
                <Button
                    color="gray"
                    className="mt-4 ml-1"
                    onClick={() => {
                        setStatusModalOpen(true);
                    }}
                >
                    <div className="flex items-center gap-x-3">
                        <HiPlus className="text-xl" />
                        <span>Добавить</span>
                    </div>
                </Button>
            </Box>
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
      statuses: state.statuses,
      statusesActions: statusesActions,
    };
};

export default connect(mapStateToProps)(StatusSettings);