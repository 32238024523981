import { Avatar, Box, IconButton, List, ListItemContent, Sheet, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { Button, ListItem } from "flowbite-react";
import { HiArrowLeft } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { vacanciesActions } from "../slices/vacancies";
import { connect, useDispatch } from "react-redux";

const VacancyList = (props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get('query');

  const handleVacancyClick = async (vacancy) => {
    setSearchParams({
      query: `${window.location.origin}/jobs?query=${vacancy.job_hash}`
    });
  }

  useEffect(()=>{
    if (!query) {
      if ((!props.vacancies.entities || props.vacancies.entities.length === 0) && !props.vacancies.loading) dispatch(props.vacanciesActions.getAll("public"));
    } else {
      if ((!props.vacancies.entity || props.vacancies.entity.length === 0) && !props.vacancies.loading) dispatch(props.vacanciesActions.get({id: query, additionalEndpoint: "public"}));
    }
  }, [query]);

  return (
    <Sheet
      className="flex w-full justify-center items-center h-dvh p-0 sm:py-16"
    >
      <Box
        className="sm:rounded-lg p-6 bg-white shadow-md h-full overflow-y-auto"
        sx={{
          width: {
            xs: '100%',
            sm: '66.666%',
            md: '50%'
          }
        }}
      >
        <Box className="flex gap-2 items-center mb-4">
          <Avatar variant="plain" src="/Logo.png" />
          <Typography level="title-lg">Вакансии Aiotex</Typography>
        </Box>
        {query && props.vacancies.entity ? (
          <Box className="vacancy-description">
            <Box className="flex flex-row justify-between">
              <IconButton 
                size="lg"
                onClick={() => setSearchParams()}>
                <HiArrowLeft />
              </IconButton>
              <Button size="md">
                Откликнуться
              </Button>
            </Box>
            <Typography className="py-4" level="title-md">{props.vacancies.entity.title}</Typography>
            {props.vacancies.entity.public_monthly_rate > 0 && 
              (<Box>
                <Typography level="body-md">От {props.vacancies.entity.min_salary_rate} руб.</Typography>
              </Box>) }
            <Typography level="body-sm">{props.vacancies.entity.location}, {props.vacancies.entity.format}, {props.vacancies.entity.vacancy_type}</Typography>
            {props.vacancies.entity.project && <Typography level="body-sm">{props.vacancies.entity.project}</Typography>}
            
            <Box className="pt-4">
              <Typography level="title-md">Описание</Typography>
              <Typography sx={{whiteSpace: "pre-line"}} level="body-md">{props.vacancies.entity.public_description}</Typography>
            </Box>
          </Box>
        ) : (
          <List>
            {props.vacancies.entities?.map((vacancy, index) => (
              <ListItem key={index}>
                <ListItemContent>
                  <Box className="rounded-lg shadow-lg p-4 space-y-4"
                    onClick={() => handleVacancyClick(vacancy)}
                  >
                    <Box>
                      <Typography level="title-md">{vacancy.title}</Typography>
                    </Box>
                    {vacancy.min_salary_rate > 0 && 
                    (<Box>
                      <Typography level="body-md">От {vacancy.min_salary_rate * vacancy.rate_type.to_hour_modificator * 145} руб.</Typography>
                    </Box>) }
                    <Box>
                      <Typography level="body-sm">{vacancy.location}</Typography>
                      <Typography level="body-sm">{vacancy.format}</Typography>
                      <Typography level="body-sm">{vacancy.vacancy_type}</Typography>
                    </Box>
                    <Box>
                      {vacancy.project && <Typography level="body-sm">{vacancy.project}</Typography>}
                    </Box>
                  </Box>
                </ListItemContent>
              </ListItem>
            ))}
          </List>
        )}
      </Box>  
    </Sheet>
    
  );
};

const mapStateToProps = (state) => {
  return {
    vacancies: state.vacancies,
    vacanciesActions: vacanciesActions,
  };
};

export default connect(mapStateToProps)(VacancyList);
