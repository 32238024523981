import { connect, useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import {
  useFetchStatuses,
  fetchVacancies,
  useJobVacancyActions,
  useFetchRateTypes,
} from "../hooks/useApi";
import { AuthContext } from "../context/AuthContext";
import Layout from "../components/layout";
import { useLoading } from "../context/LoadingContext";
import {
  Badge,
  Breadcrumb,
  Button,
  Drawer,
  Label,
  TextInput,
} from "flowbite-react";
import {
  HiFire,
  HiHome,
  HiPlus,
  HiViewBoards,
  HiViewList,
} from "react-icons/hi";
import Kanban from "../components/kanban";
import {
  Box,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
} from "@mui/joy";
import GenericTable from "../components/tableAdmin";
import { BASE_NEW_RATE_TYPE } from "../config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { statusesActions } from "../slices/statuses";
import { vacanciesActions } from "../slices/vacancies";
import { useCustomSearchParams } from "../components/utils";

const VacancyCard = (props) => {
  return (
    <div
      key={props.data.id}
      className="p-2 rounded shadow-sm border-gray-100 border-2"
    >
      <h3
        className="text-md mb-3 text-gray-900 hover:text-cyan-700"
        onClick={() => props.onCardClick({ id: props.data.id })}
      >
        {props.data.title}
      </h3>
      <div className="flex flex-row items-center mt-2 text-sm gap-2">
        {props.data.stacks.map((stack) => (
          <Badge key={stack.id} color="green">
            {stack.name}
          </Badge>
        ))}
      </div>
      <div className="flex flex-col items-start mt-2">
        <a
          target="_blank"
          className="text-sm text-gray-700 hover:text-cyan-700"
          href={`/partners/${props.data.partner.id}`}
        >
          Партнер: {props.data.partner.name}
        </a>
        <a
          target="_blank"
          className="text-sm text-gray-700 hover:text-cyan-700"
          href={`/partners/${props.data.client.id}`}
        >
          Клиент: {props.data.client.name}
        </a>
      </div>

      <p className="text-sm text-cyan-700">
        Часовой рейт:{" "}
        {props.data.max_salary_rate * props.data.rate_type.to_hour_modificator}
      </p>
    </div>
  );
};

const VacancyListPage = (props) => {
  const dispatch = useDispatch();
  const [viewKanban, setViewKanban] = useState(true);
  const [searchObject, searchParams, setSearchParams] = useCustomSearchParams();


  const handleDelete = async (v) => {
    if (window.confirm("Эту вакансию точно нужно удалить?")) {
      dispatch(props.vacanciesActions.drop({id: v}));
    }
  };

  const handleCardClick = ({ id }) => {
    setSearchParams({
      ...searchObject,
      view: "vacancy", id: id});
  };

  const handleCardDrop = async (id, status) => {
    dispatch(props.vacanciesActions.update({
      id: id,
      data: {
          status: status
      }})
    )
  };

  async function handleSearchVacancy(query) {
    setSearchParams({
      ...searchObject,
      search: query
    })
  };

  function filtred(data) {
    const search = searchParams.get('search');
    if (search) {
      return data.filter(vac => vac.external_id?.includes(search) || vac.title.toLowerCase().includes(search.toLowerCase()))
    } else {
      return data
    }
  }

  useEffect(() => {
    if ((!props.vacancies.entities || props.vacancies.entities.length === 0) && !props.vacancies.loading) dispatch(props.vacanciesActions.getAll("simple"));
    if (
      (!props.statuses.entities || props.statuses.entities.length === 0 || props.statuses.lastUpdated === "filtred") 
      && !props.statuses.loading) dispatch(props.statusesActions.getAll())
  }, []);

  return (
    <>
      <div class="overflow-x-auto h-full">
        <div>
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href="/">
              <div className="flex items-center gap-x-3">
                <HiHome className="text-xl" />
                <span className="dark:text-white">Админка</span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Вакансии</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            Управление вакансиями
          </h1>
        </div>
        <Box className="flex flex-row justify-between">
          <Box className="space-x-2 pt-4">
            <IconButton
              size="md"
              variant={viewKanban ? "solid" : "outlined"}
              onClick={() => setViewKanban(true)}
            >
              <HiViewBoards />
            </IconButton>
            <IconButton
              size="md"
              variant={viewKanban ? "outlined" : "solid"}
              onClick={() => setViewKanban(false)}
            >
              <HiViewList />
            </IconButton>
          </Box>
          <Button
            color="gray"
            className="mt-4 ml-1"
            onClick={() => {
              setSearchParams({
                ...searchObject,
                id: 'new', view: 'vacancy'})
            }}
          >
            <div className="flex items-center gap-x-3">
              <HiPlus className="text-xl" />
              <span>Добавить</span>
            </div>
          </Button>  
        </Box>
        
        <div className="mt-4 h-full">
          {viewKanban ? (
            <Kanban
              onDragEnd={(cardId, colId) => handleCardDrop(cardId, colId)}
              onSearch={handleSearchVacancy}
              filters={[
                { label: "Клиент", column: "client", placeholder: "Клиент" },
                {
                  label: "Партнёр",
                  column: "partner",
                  placeholder: "Партнёр",
                },
                { label: "Стэк", column: "stacks", placeholder: "Стэк" },
                {
                  label: "Маск. часовой рейт",
                  column: "hour_rate",
                  placeholder: "Часовой рейт",
                  type: "range",
                },
              ]}
              columns={props.statuses.entities.filter(s => ["job_vacancy"].includes(s.relation)).slice().sort(
                  (a, b) => a.weight - b.weight,
                )?.filter((v) => v.weight < 10)
                .map((s) => ({
                  id: s.id,
                  title: s.name,
                  color: s.color,
                  cards: filtred(props.vacancies.entities)
                    ?.filter((vacancy) => vacancy.status?.id === s.id)
                    .map((vacancy) => ({
                      id: vacancy?.id,
                      name: vacancy.title,
                      child: (
                        <VacancyCard
                          data={vacancy}
                          onCardClick={handleCardClick}
                        />
                      ),
                    })),
                }))}
            />
          ) : (
            <>
              <GenericTable
                data={filtred(props.vacancies.entities)}
                onSearch={handleSearchVacancy}
                columns={[
                  { name: "id", alias: "ID" },
                  { name: "external_id", alias: "Внешний ID" },
                  { name: "title", alias: "Название" },
                  { name: "status", alias: "Статус", badges: props.statuses.entities.filter(s => ["job_vacancy"].includes(s.relation)), order: "weight" },
                  {
                    name: "updated_at",
                    alias: "Дата обновления",
                    mod: (id, value) => {
                      return new Date(value).toLocaleDateString("ru-RU", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });
                    },
                  },
                  { name: "stacks", alias: "Стэк" },
                  { name: "hour_rate", alias: "Часовой рейт" },
                  {
                    name: "priority",
                    alias: "Приоритет",
                    badges: [
                      {
                        id: 1,
                        color: "failure",
                        name: (
                          <div className="flex flex-row place-items-center space-x-1">
                            <HiFire color="red" />
                            Высокий
                          </div>
                        ),
                      },
                      { id: 2, color: "warning", name: "Средний" },
                      { id: 3, color: "info", name: "Низкий" },
                    ],
                  },
                  { name: "format", alias: "Формат" },
                  { name: "location", alias: "Локация" },
                  { name: "client", alias: "Клиент" },
                  { name: "partner", alias: "Партнёр" },
                  {
                    name: "created_at",
                    alias: "Дата создания",
                    mod: (id, value) => {
                      return new Date(value).toLocaleDateString("ru-RU", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });
                    },
                  },
                ]}
                filters={[
                  { label: "Статус", column: "status", placeholder: "Статус" },
                  { label: "Клиент", column: "client", placeholder: "Клиент" },
                  {
                    label: "Партнёр",
                    column: "partner",
                    placeholder: "Партнёр",
                  },
                  { label: "Стэк", column: "stacks", placeholder: "Стэк" },
                  {
                    label: "Маск. часовой рейт",
                    column: "hour_rate",
                    placeholder: "Часовой рейт",
                    type: "range",
                  },
                ]}
                orderableColumns={[
                  "created_at",
                  "status",
                  "hour_rate",
                  "priority",
                ]}
                onDelete={handleDelete}
                onClick={handleCardClick}
                renderCard={(data) =>
                  <VacancyCard data={data} onCardClick={handleCardClick} />
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    statuses: state.statuses,
    vacancies: state.vacancies,
    vacanciesActions: vacanciesActions,
    statusesActions: statusesActions,
  };
};

export default connect(mapStateToProps)(VacancyListPage);
