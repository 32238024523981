import { styled } from "@mui/joy";
import { useSearchParams } from "react-router-dom";

export function openSidebar() {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
  }
  
  export function closeSidebar() {
    if (typeof window !== 'undefined') {
      document.documentElement.style.removeProperty('--SideNavigation-slideIn');
      document.body.style.removeProperty('overflow');
    }
  }
  
  export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const slideIn = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--SideNavigation-slideIn');
      if (slideIn) {
        closeSidebar();
      } else {
        openSidebar();
      }
    }
  }

  export function flattenValues(val) {
    if (typeof val === "string" || typeof val === "number" || typeof val === "boolean") return val;
    if (!val) return undefined;
    if (val.name) return val.name;
    if (val.username) return val.username;
    if (val.title) return val.title;
    if (val.alias) return val.alias;
    if (val.comment) return val.comment;
    if (val.value && (typeof val.value === "string" || typeof val.value === "number" || typeof val === "boolean")) return val.value;
    return undefined;
  }

  export function flattenKeys(val) {
    if (typeof val === "string" || typeof val === "number" || typeof val === "boolean") return val;
    if (!val) return undefined;
    if (val.id) return val.id;
    if (val.key) return val.key;
    if (val.name) return val.name;
    console.warn(`Can't extract flatten key from val ${val}`);
    return undefined;
  }

  /** 
* Функция 'Порядок наведу'.
* Очищает строки 'html' от ненужных элементов (HTML-тегов) и возвращает их в первоначальном виде.
*/
export function stripHtml(html) {
  let tempHolder = document.createElement("DIV");
  tempHolder.innerHTML = html;
  return tempHolder.textContent || tempHolder.innerText || "";
}

export const useCustomSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );

  return [searchObject, searchParams, setSearchParams];
};