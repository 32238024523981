import { connect, useDispatch } from "react-redux";
import {
    Badge,
    Breadcrumb,
    Button,
    Label,
    Modal,
    Select,
    TextInput,
    Checkbox,
} from "flowbite-react";
import { HiHome, HiPlus } from "react-icons/hi";
import { Box, Typography } from "@mui/joy";
import { useContext, useEffect, useReducer, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import GenericTable from "../components/tableAdmin";
import { useFetchPartnerTypes, useFetchStatuses, useUtilsActions } from "../hooks/useApi";
import {
    EditableBadgeField,
    EditableTextField,
} from "../components/editableField";
import { useSearchParams } from "react-router-dom";
import { partnerTypesActions } from "../slices/partnerTypes";

const PartnerSettings = (props) => {
    const dispatch = useDispatch();

    const [typeModalOpen, setTypeModalOpen] = useState(false);

    const handleSubmitType = async (event) => {
        event.preventDefault();
        dispatch(props.partnerTypesActions.create({data: {
            name: event.target.typeName.value,
        }}))
        setTypeModalOpen(false);
    };
    const handleUpdateType = ({ id, column, value }) => {
        dispatch(props.partnerTypesActions.update({id, data: {[column]: value}}))
    };

    const handleUpdateDefault = ({id, is_default}) => {
        if (is_default) {
            dispatch(props.partnerTypesActions.updateState(
                props.partnerTypes.entities.map((p) => ({...p, is_default: false}))
            ))
        }
        dispatch(props.partnerTypesActions.update({id, data: {is_default}}))
    }

    const handleDeleteType = (id) => {
        dispatch(props.partnerTypesActions.drop({id}))
    };

    useEffect(()=>{
        if ((!props.partnerTypes.entities || props.partnerTypes.entities.length === 0) && !props.partnerTypes.loading) dispatch(props.partnerTypesActions.getAll());
    }, [])

    return (
        <Box>
            <Box>
                <Typography level="h4" component="h1">
                    Типы партнеров
                </Typography>
                <Modal
                    onClose={() => setTypeModalOpen(false)}
                    show={typeModalOpen}
                >
                    <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                        <strong>Создать тип</strong>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            onSubmit={handleSubmitType}
                            className="grid grid-cols-1 gap-6 sm:grid-cols-2"
                        >
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="typeName"
                                        value="Название"
                                    />
                                </div>
                                <TextInput
                                    id="typeName"
                                    type="text"
                                    placeholder="Статус"
                                    required
                                />
                            </div>
                            <Button type="submit">Создать</Button>
                        </form>
                    </Modal.Body>
                </Modal>
                <GenericTable
                    data={props.partnerTypes.entities}
                    columns={[
                        { name: "id", alias: "ID" },
                        {
                            name: "name",
                            alias: "Название",
                            mod: (obj_id, value) => (
                                <EditableTextField
                                    value={value}
                                    onApply={(v) =>
                                        handleUpdateType({
                                            id: obj_id,
                                            column: "name",
                                            value: v,
                                        })
                                    }
                                />
                            ),
                        },
                        { name: "is_default", alias: "По умолчанию", mod: 
                            (obj_id, value) => {
                                return <Checkbox checked={!!value} onChange={
                                    () => handleUpdateDefault({
                                        id: obj_id,
                                        is_default: !!!value
                                    })
                                } />
                            }
                        }
                    ]}
                    onDelete={handleDeleteType}
                />
                <Button
                    color="gray"
                    className="mt-4 ml-1"
                    onClick={() => {
                        setTypeModalOpen(true);
                    }}
                >
                    <div className="flex items-center gap-x-3">
                        <HiPlus className="text-xl" />
                        <span>Добавить</span>
                    </div>
                </Button>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
      partnerTypes: state.partnerTypes,
      partnerTypesActions: partnerTypesActions,
    };
};

export default connect(mapStateToProps)(PartnerSettings);