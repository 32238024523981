import React, { Component, useEffect, useState } from "react";
import { NavLink, matchPath, useLocation, useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import LoginForm from "./LoginForm";
import { AuthContext } from "../context/AuthContext";
import { Button, Sidebar as Sb } from "flowbite-react";
import { HiBriefcase, HiChevronDown, HiCog, HiHome, HiUserGroup, HiUsers } from "react-icons/hi";
import { Box, Divider, GlobalStyles, IconButton, List, ListItem, ListItemButton, ListItemContent, Sheet, Typography } from "@mui/joy";
import { closeSidebar } from "./utils";
import { HiArrowRightOnRectangle } from "react-icons/hi2";

const Toggler = ({
    defaultExpanded = false,
    renderToggle,
    children,
}) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {setOpen(defaultExpanded)}, [defaultExpanded])
    return (
        <React.Fragment>
          {renderToggle({ open, setOpen })}
          <Box
            sx={[
              {
                display: 'grid',
                transition: '0.2s ease',
                '& > *': {
                  overflow: 'hidden',
                },
              },
              open ? { gridTemplateRows: '1fr' } : { gridTemplateRows: '0fr' },
            ]}
          >
            {children}
          </Box>
        </React.Fragment>
      );
}

const Sidebar = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const routes = ["/dashboard", "/users", "/partners", "/vacancies", "/settings", "/recruitment", "/processing"];
    const [ route, setRoute ] = useState("/") 
    const { pathname } = useLocation();

    useEffect(() => {
        const fetchPathPattern = () => {
            return routes.find((route) => matchPath(route, pathname));
        };
        
        setRoute(fetchPathPattern());
    }, [pathname])

    return (
        <Sheet 
            className="Sidebar"
            sx={{
                position: { xs: 'fixed', md: 'sticky' },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                ':root': {
                    '--Sidebar-width': '220px',
                    [theme.breakpoints.up('lg')]: {
                    '--Sidebar-width': '240px',
                    },
                },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                position: 'fixed',
                zIndex: 9998,
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                opacity: 'var(--SideNavigation-slideIn)',
                backgroundColor: 'var(--joy-palette-background-backdrop)',
                transition: 'opacity 0.4s',
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                    lg: 'translateX(-100%)',
                },
                }}
                onClick={() => closeSidebar()}
            />
            <Box className="flex gap-1 items-center">
                <Typography level="title-lg">Aiotex.Core</Typography>
            </Box>
            <Divider />
            <List 
                size="sm" 
                className="gap-1"
                sx={{
                    '--List-nestedInsetStart': '30px',
                    '--ListItem-radius': (theme) => theme.vars.radius.sm,
                }}
            >
                <ListItemButton onClick={()=>navigate("/")} selected={(route == "/")}>
                    <HiHome />
                    <ListItemContent>
                        <Typography level="title-sm">Статистика</Typography>
                    </ListItemContent>
                </ListItemButton>

                <ListItemButton onClick={()=>navigate("/partners")} selected={(route == "/partners")}>
                    <HiUserGroup />
                    <ListItemContent>
                        <Typography level="title-sm">Контрагенты</Typography>
                    </ListItemContent>
                </ListItemButton>

                <ListItem nested>
                    <Toggler
                        defaultExpanded={[
                            "/vacancies",
                            "/processing",
                            "/recruitment",
                            ].includes(route)}
                        renderToggle={({ open, setOpen }) => (
                            <ListItemButton onClick={() => setOpen(!open)}>
                            <HiBriefcase />
                            <ListItemContent>
                                <Typography level="title-sm">HR</Typography>
                            </ListItemContent>
                            <HiChevronDown/>
                            </ListItemButton>
                        )}
                    >
                        <List>
                            <ListItem>
                                <ListItemButton>
                                    <ListItemButton 
                                        onClick={()=>navigate("/vacancies")} 
                                        selected={(route === "/vacancies")}>Вакансии</ListItemButton> 
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton>
                                    <ListItemButton 
                                        onClick={()=>navigate("/processing")} 
                                        selected={(route === "/processing")}>Процессинг</ListItemButton> 
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton>
                                    <ListItemButton 
                                        onClick={()=>navigate("/recruitment")} 
                                        selected={(route === "/recruitment")}>Рекрутмент</ListItemButton> 
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Toggler>
                </ListItem>

                {/* <ListItemButton onClick={()=>navigate("/settings")} selected={(route == "/settings")}>
                    <HiCog />
                    <ListItemContent>
                        <Typography level="title-sm">Настройки</Typography>
                    </ListItemContent>
                </ListItemButton> */}

                <ListItem nested>
                    <Toggler
                        defaultExpanded={[
                            "/settings",
                            ].includes(route)}
                        renderToggle={({ open, setOpen }) => (
                            <ListItemButton onClick={() => setOpen(!open)}>
                            <HiCog />
                            <ListItemContent>
                                <Typography level="title-sm">Настройки</Typography>
                            </ListItemContent>
                            <HiChevronDown/>
                            </ListItemButton>
                        )}
                    >
                        <List>
                            <ListItem>
                                <ListItemButton>
                                    <ListItemButton 
                                        onClick={()=>navigate({pathname: "/settings", search: createSearchParams({view: "statuses"}).toString()})} 
                                        selected={(route === "/settings" && searchParams.get("view") === "statuses")}>Статусы</ListItemButton> 
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton>
                                    <ListItemButton 
                                        onClick={()=>navigate({pathname: "/settings", search: createSearchParams({view: "partner_types"}).toString()})} 
                                        selected={(route === "/settings" && searchParams.get("view") === "partner_types")}>Типы партнеров</ListItemButton> 
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Toggler>
                </ListItem>
                
                <ListItem nested>
                    <Toggler
                    defaultExpanded={["/users", "/users/profile"].includes(route)}
                    renderToggle={({ open, setOpen }) => (
                        <ListItemButton onClick={() => setOpen(!open)}>
                        <HiUsers />
                        <ListItemContent>
                            <Typography level="title-sm">Пользователи</Typography>
                        </ListItemContent>
                        <HiChevronDown/>
                        </ListItemButton>
                    )}
                    >
                    <List>
                        <ListItem>
                        <ListItemButton>
                            Мой профиль
                        </ListItemButton>
                        </ListItem>
                        <ListItem>
                        <ListItemButton onClick={()=>navigate("/users")} selected={(route == "/users")}>Пользователи и роли</ListItemButton>
                        </ListItem>
                    </List>
                    </Toggler>
                </ListItem>
            </List>
            <Divider />
            <AuthContext.Consumer>
                {({ logout, user }) => (
                    <Box className="flex items-center gap-1">
                        <Box sx={{ minWidth: 0, flex: 1 }}>
                            <Typography level="title-sm">{user.username}</Typography>
                            <Typography level="body-xs">{user.email}</Typography>
                        </Box>
                        <IconButton size="sm" variant="plain" color="neutral" onClick={()=>logout()}>
                        <HiArrowRightOnRectangle />
                        </IconButton>
                    </Box>
                )}
            </AuthContext.Consumer>
        </Sheet>
    )
}

export default Sidebar;
