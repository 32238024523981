import {
    buildCreateSlice,
    asyncThunkCreator,
    createReducer,
} from "@reduxjs/toolkit";
import { dataSevice } from "../services/api";
import { flattenKeys } from "../components/utils";

export const apiSlice = (name, endpoint, initialState) => {
    const services = dataSevice(endpoint);
    const createApiSlice = buildCreateSlice({
        creators: { asyncThunk: asyncThunkCreator, reducer: createReducer },
    });
    return createApiSlice({
        name: name,
        initialState: initialState,
        reducers: (create) => ({
            updateState: create.reducer((state, action) => {
                if (action.payload?.target === "entity") {
                    state.entity = action.payload.data;
                } else {
                    state.entities = [...action.payload];
                }
            }),
            getAll: create.asyncThunk(
                async (additionalEndpoint="") => { 
                    const res = await services.getAll(additionalEndpoint);
                    return res.data;
                },
                {
                    fulfilled: (state, action) => {
                        state.entities = [...action.payload];
                        state.loading = false;
                        state.lastUpdated = null;
                    },
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                },
            ),
            get: create.asyncThunk(
                async ({ id, additionalEndpoint }) => {
                    const res = await services.get(id, additionalEndpoint);
                    return res.data;
                },
                {
                    fulfilled: (state, action) => {
                        state.entity = action.payload;
                        state.loading = false;
                    },
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                }
            ),
            getFiltred: create.asyncThunk(
                async ({ data }) => {
                    
                    const res = await services.getAll(data);
                    return res.data;
                },
                {
                    fulfilled: (state, action) => {
                        state.entities = [...action.payload];
                        state.lastUpdated = "filtred";
                        state.loading = false;
                    },
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                },
            ),
            create: create.asyncThunk(
                async ({ data, additionalEndpoint="", headers=null }) => {
                    
                    const res = await services.create(data, additionalEndpoint, headers);
                    return res.data;
                },
                {
                    fulfilled: (state, action) => {
                        state.entities.push(action.payload);
                        state.entity = action.payload;
                        state.loading = false;
                        state.lastUpdated = action.payload;
                    },
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                },
            ),
            update: create.asyncThunk(
                async ({ id, data }) => {
                    
                    const res = await services.update(id, data);
                    return res.data;
                },
                {
                    fulfilled: (state, action) => {
                        const index = state.entities.findIndex(
                            (object) =>
                                flattenKeys(object) ===
                                flattenKeys(action.payload),
                        );
                        state.entities[index] = {
                            ...state[index],
                            ...action.payload,
                        };
                        state.entity = action.payload;
                        state.lastUpdated = action.payload;
                        state.loading = false;
                    },
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                },
            ),
            drop: create.asyncThunk(
                async ({ id }) => {
                    
                    const res = await services.delete(id);
                    return res.data;
                },
                {
                    fulfilled: (state, action) => {
                        state.entities = state.entities.filter(
                            (e) =>
                                flattenKeys(e) != flattenKeys(action.meta.arg),
                        );
                        state.loading = false;
                    },
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                },
            ),
        }),
    });
};
