import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { API_BASE_URL, BASE_NEW_RATE_TYPE } from "../config";

const useApiRequest = (token, endpoint, method = "get", body = null, filter = null) => {
    const config = {
        method,
        url: `${API_BASE_URL}${endpoint}`,
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            // "Access-Control-Allow-Headers":
            //     "Content-Type, Authorization, X-Requested-With",
        },
        data: body,
    };
    if (filter) config.params = filter;
    return axios(config);
};

export function useFetchPartnerTypes(token, updateTrigger) {
    const apiRequest = useApiRequest;
    const [partnerTypes, setPartnerTypes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(
                    token,
                    `/utils/partner_types`,
                );
                setPartnerTypes(response.data);
            } catch (error) {
                console.error("Failed to fetch partner types data", error);
            }
        };

        fetchData();
    }, [token, updateTrigger]);

    return partnerTypes;
}

export function useFetchStatuses(token, category = null, updateTrigger = 0) {
    const apiRequest = useApiRequest;
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(
                    token,
                    `/utils/status${category ? "?relation=" + category : ""}`,
                );
                setStatuses(response.data);
            } catch (error) {
                console.error("Failed to fetch status data", error);
            }
        };

        fetchData();
        console.log("Fetch statues");
    }, [token, category, updateTrigger]);

    return statuses;
}

export function useUtilsActions(token) {
    const apiRequest = useApiRequest;

    const handleUtils = async (entityId, entityData, type, method) => {
        try {
            const response = await apiRequest(
                token,
                `/utils/${type}${entityId ? "/" + entityId : ""}`,
                method,
                entityData,
            );
            console.log(
                "Fetch status update",
                entityId,
                entityData,
                response?.data,
            );
            return response?.data;
        } catch (error) {
            console.error(`Failed to ${method} ${type}`, error);
        }
    };

    return {
        getStatuses: () => {
            return handleUtils(null, null, "status", "get");
        },
        addStatuses: (data) => handleUtils(null, data, "status", "post"),
        updateStatuses: (entityId, data) =>
            handleUtils(entityId, data, "status", "put"),
        deleteStatuses: (entityId) =>
            handleUtils(entityId, null, "status", "delete"),

        getPartnerTypes: () => {
            return handleUtils(null, null, "partner_types", "get");
        },
        addPartnerTypes: (data) => handleUtils(null, data, "partner_types", "post"),
        updatePartnerTypes: (entityId, data) =>
            handleUtils(entityId, data, "partner_types", "put"),
        deletePartnerTypes: (entityId) =>
            handleUtils(entityId, null, "partner_types", "delete"),
    };
}

export function useFetchUsers(token, updateTrigger) {
    const apiRequest = useApiRequest;

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(token, "/users");
                setUserData(response.data);
            } catch (error) {
                console.error("Failed to fetch users data", error);
            }
        };

        fetchData();
    }, [updateTrigger]);

    return userData;
}

export async function fetchUsers(token) {
    const apiRequest = useApiRequest;

    try {
        const response = await apiRequest(token, "/users");
        return response.data;
    } catch (error) {
        console.error("Failed to fetch users data", error);
    }
}

export function useUserActions(token) {
    const apiRequest = useApiRequest;

    const handleCommit = async (id, data) => {
        try {
            const method = id ? "put" : "post";
            const endpoint = id ? `/users/${id}` : "/users";

            await apiRequest(token, endpoint, method, data);
        } catch (error) {
            console.error("Failed to commit partner data", error);
            if (error.response?.data?.message?.message) {
                window.alert(error.response.data.message.message);
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            await apiRequest(token, `/users/${id}`, "delete");
        } catch (error) {
            console.error("Failed to delete user data", error);
        }
    };

    return { handleCommit, handleDelete };
}

export function useFetchPartners(token, updateTrigger) {
    const apiRequest = useApiRequest;

    const [partnerData, setPartnerData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(token, "/partners");
                setPartnerData(response.data);
            } catch (error) {
                console.error("Failed to fetch partners data", error);
            }
        };

        fetchData();
    }, [updateTrigger]);

    return partnerData;
}

export async function fetchPartners(token) {
    const apiRequest = useApiRequest;
    try {
        const response = await apiRequest(token, "/partners");
        return response.data;
    } catch (error) {
        console.error("Failed to fetch partners data", error);
    }
}

export function usePartnerActions(token) {
    const apiRequest = useApiRequest;

    const handleCommit = async (id, data) => {
        try {
            const method = id ? "put" : "post";
            const endpoint = id ? `/partners/${id}` : "/partners";

            const response = await apiRequest(token, endpoint, method, data);
            return response.data
        } catch (error) {
            console.error("Failed to commit partner data", error);
            if (error.response?.data?.message?.message) {
                window.alert(error.response.data.message.message);
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            await apiRequest(token, `/partners/${id}`, "delete");
        } catch (error) {
            console.error("Failed to delete partner data", error);
        }
    };

    return { handleCommit, handleDelete };
}

export function useFetchPartnerDetail(token, partnerId, updateTrigger) {
    const apiRequest = useApiRequest;
    const [partnerDetail, setPartnerDetail] = useState({
        name: "",
        types: [],
        is_group: false,
        parent_id: null,
        status: {},
        contacts: [],
        requisites: [],
        documents: [],
    });

    useEffect(() => {
        if (partnerId) {
            const fetchData = async () => {
                try {
                    const response = await apiRequest(
                        token,
                        `/partners/${partnerId}`,
                    );

                    setPartnerDetail((prev) => ({
                        ...prev,
                        id: response.data.id,
                        name: response.data.name,
                        types: response.data.types,
                        is_group: response.data.is_group,
                        parent_id: response.data.parent_id,
                        status: response.data.status,
                    }));

                    const promises = ["requisites", "contacts", "documents"].map(
                        (section) =>
                            apiRequest(token, `/partners/${partnerId}/${section}`),
                    );

                    const [requisites, contacts, documents] =
                        await Promise.all(promises);

                    let docs = []
                    for (let doc of documents.data) {
                        if (doc.s3_file) {
                            const url = await apiRequest(token, `/partners/${partnerId}/document/${doc.id}/download-url`);
                            doc.url = url;
                        }
                        docs.push(doc);
                    }

                    setPartnerDetail((prev) => ({
                        ...prev,
                        requisites: requisites.data,
                        contacts: contacts.data,
                        documents: docs,
                    }));
                } catch (error) {
                    console.error("Failed to fetch partners data", error);
                }
            };

            fetchData();
        } else {
            setPartnerDetail(null);
        }
    }, [partnerId, updateTrigger]);

    return {
        partnerDetail,
        setPartnerDetail,
    };
}

export function usePartnerDetailActions(token) {
    const apiRequest = useApiRequest;

    const createEntity = async (partnerId, entityData, entityType) => {
        try {
            await apiRequest(
                token,
                `/partners/${partnerId}/${entityType}`,
                "post",
                entityData,
            );
        } catch (error) {
            console.error(`Failed to create ${entityType}`, error);
        }
    };

    const updateEntity = async (
        partnerId,
        entityId,
        entityData,
        entityType,
    ) => {
        try {
            await apiRequest(
                token,
                `/partners/${partnerId}/${entityType}/${entityId}`,
                "put",
                entityData,
            );
        } catch (error) {
            console.error(`Failed to update ${entityType}`, error);
        }
    };

    const deleteEntity = async (partnerId, entityId, entityType) => {
        try {
            await apiRequest(
                token,
                `/partners/${partnerId}/${entityType}/${entityId}`,
                "delete",
            );
        } catch (error) {
            console.error(`Failed to delete ${entityType}`, error);
        }
    };

    return {
        createContact: (partnerId, contactData) =>
            createEntity(partnerId, contactData, "contacts"),
        putContact: (partnerId, contactId, contactData) =>
            updateEntity(partnerId, contactId, contactData, "contacts"),
        deleteContact: (partnerId, contactId) =>
            deleteEntity(partnerId, contactId, "contacts"),
        createRequisit: (partnerId, requisitData) =>
            createEntity(partnerId, requisitData, "requisites"),
        putRequisit: (partnerId, requisiteId, requisitData) =>
            updateEntity(partnerId, requisiteId, requisitData, "requisites"),
        deleteRequisites: (partnerId, requisiteId) =>
            deleteEntity(partnerId, requisiteId, "requisites"),
    };
}

export function useFetchVacancies(token, updateTrigger) {
    const apiRequest = useApiRequest;

    const [vacancyData, setVacancyData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(token, "/job_vacancies");
                setVacancyData(response.data);
            } catch (error) {
                console.error("Failed to fetch vacancies data", error);
            }
        };

        fetchData();
    }, [updateTrigger]);

    return vacancyData;
}

export function useFetchPublicVacancies(updateTrigger) {
    const apiRequest = useApiRequest;

    const [vacancyData, setVacancyData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(
                    null,
                    "/job_vacancies/public",
                );
                setVacancyData(response.data);
            } catch (error) {
                console.error("Failed to fetch vacancies data", error);
            }
        };

        fetchData();
    }, [updateTrigger]);

    return vacancyData;
}

export async function fetchVacancies(token) {
    const apiRequest = useApiRequest;

    try {
        const response = await apiRequest(token, "/job_vacancies/simple");
        return response.data;
    } catch (error) {
        console.error("Failed to fetch vacancies data", error);
    }
}

export function useFetchVacancyDetail(token, vacancyId, updateTrigger, templateData = {statuses: [], user: {}, role: ""}) {
    const templateVacancy = () => {
        return {
          title: "Новая вакансия",
          description: "Описание",
          min_salary_rate: 0,
          max_salary_rate: 0,
          rate_type: { id: BASE_NEW_RATE_TYPE },
          vacancy_type: "Fulltime",
          project: "Проект",
          project_length: "Бессрочний",
          priority: 1,
          location: "РФ",
          citizenship: "РФ",
          format: "Remote",
          status: templateData.statuses.find((value) => value.name.toLowerCase() === "черновик"),
          author: templateData.user.partner,
          client: null,
          partner: null,
          contractor: null,
          stacks: [],
          processing: [],
        };
      };

    const apiRequest = useApiRequest;
    const [vacancyDetail, setVacancyDetail] = useState(null);

    useEffect(() => {
        if (vacancyId) {
            if (vacancyId != "new") {
                const fetchData = async () => {
                    try {
                        const response = await apiRequest(
                            token,
                            `/job_vacancies/${vacancyId}`,
                        );
                        setVacancyDetail(response.data);
                    } catch (error) {
                        console.error("Failed to fetch vacancy data", error);
                    }
                };

                fetchData();
            } else {
                setVacancyDetail(templateVacancy());
            }
        } else { setVacancyDetail(null) }
    }, [vacancyId, updateTrigger]);

    return [ vacancyDetail, setVacancyDetail ];
}

export async function fetchVacancyDetail(token, vacancyId) {
    const apiRequest = useApiRequest;

    try {
        const response = await apiRequest(token, `/job_vacancies/${vacancyId}`);
        return response.data;
    } catch (error) {
        console.error("Failed to fetch vacancy data", error);
    }
}

export function useJobVacancyActions(token) {
    const apiRequest = useApiRequest;

    const createJobVacancy = async (jobVacancyData) => {
        try {
            const response = await apiRequest(
                token,
                `/job_vacancies/`,
                "post",
                jobVacancyData,
            );
            return response.data;
        } catch (error) {
            console.error("Failed to create job vacancy", error);
        }
    };

    const updateJobVacancy = async (vacancyId, jobVacancyData) => {
        try {
            console.log(vacancyId, jobVacancyData);
            await apiRequest(
                token,
                `/job_vacancies/${vacancyId}`,
                "put",
                jobVacancyData,
            );
        } catch (error) {
            console.error("Failed to update job vacancy", error);
        }
    };

    const deleteJobVacancy = async (vacancyId) => {
        try {
            await apiRequest(token, `/job_vacancies/${vacancyId}`, "delete");
        } catch (error) {
            console.error("Failed to delete job vacancy", error);
        }
    };

    return {
        createJobVacancy,
        updateJobVacancy,
        deleteJobVacancy,
    };
}

export function useJobRelatedActions(token) {
    const apiRequest = useApiRequest;

    const handleJobRelated = async (entityId, entityData, type, method, filter) => {
        try {
            const response = await apiRequest(
                token,
                `/job_related/${type}${entityId ? "/" + entityId : ""}`,
                method,
                entityData,
                filter
            );

            return response?.data;
        } catch (error) {
            console.error(`Failed to ${method} ${type}`, error);
        }
    };

    return {
        getRequirements: () => {
            return handleJobRelated(null, null, "requirements", "get");
        },
        addRequirement: (data) =>
            handleJobRelated(null, data, "requirements", "post"),
        updateRequirement: (entityId, data) =>
            handleJobRelated(entityId, data, "requirements", "put"),
        deleteRequirement: (entityId) =>
            handleJobRelated(entityId, null, "requirements", "delete"),

        getResponsibilities: () => {
            return handleJobRelated(null, null, "responsibilities", "get");
        },
        addResponsibility: (data) =>
            handleJobRelated(null, data, "responsibilities", "post"),
        updateResponsibility: (entityId, data) =>
            handleJobRelated(entityId, data, "responsibilities", "put"),
        deleteResponsibility: (entityId) =>
            handleJobRelated(entityId, null, "responsibilities", "delete"),

        addStack: (data) => handleJobRelated(null, data, "stacks", "post"),
        updateStack: (entityId, data) =>
            handleJobRelated(entityId, data, "stacks", "put"),
        deleteStack: (entityId) =>
            handleJobRelated(entityId, null, "stacks", "delete"),

        getProcessing: () => {
            return handleJobRelated(null, null, "processing", "get")
        },
        getProcessingExtended: () => {
            return handleJobRelated(null, null, "processing", "get")
        },
        getProcessingFiltred: (filter) => {
            let f = "";
            return handleJobRelated(null, null, "processing", "get", filter)
        },
        addProcessing: (data) =>
            handleJobRelated(null, data, "processing", "post"),
        updateProcessing: (entityId, data) =>
            handleJobRelated(entityId, data, "processing", "put"),
        deleteProcessing: (entityId) =>
            handleJobRelated(
                entityId,
                null,
                "processing",
                "delete",
            ),
        getRecruitment: () => {
            return handleJobRelated(null, null, "recruitment", "get")
        },
        addRecruitment: (data) =>
            handleJobRelated(null, data, "recruitment", "post"),
        updateRecruitment: (entityId, data) =>
            handleJobRelated(entityId, data, "recruitment", "put"),
        deleteRecruitment: (entityId) =>
            handleJobRelated(
                entityId,
                null,
                "recruitment",
                "delete",
            ),

        addScoring: (data) => handleJobRelated(null, data, "scoring", "post"),
        updateScoring: (entityId, data) =>
            handleJobRelated(entityId, data, "scoring", "put"),
        deleteScoring: (entityId) =>
            handleJobRelated(entityId, null, "scoring", "delete"),

        addScoringCriteria: (data) =>
            handleJobRelated(null, data, "scoring_criteria", "post"),
        updateScoringCriteria: (entityId, data) =>
            handleJobRelated(entityId, data, "scoring_criteria", "put"),
        deleteScoringCriteria: (entityId) =>
            handleJobRelated(entityId, null, "scoring_criteria", "delete"),

        addScoringAnswer: (data) =>
            handleJobRelated(null, data, "scoring_answer", "post"),
        updateScoringAnswer: (entityId, data) =>
            handleJobRelated(entityId, data, "scoring_answer", "put"),
        deleteScoringAnswer: (entityId) =>
            handleJobRelated(entityId, null, "scoring_answer", "delete"),
    };
}

export async function fetchStack(token) {
    const apiRequest = useApiRequest;

    try {
        const response = await apiRequest(token, "/job_related/stacks");
        return response.data;
    } catch (error) {
        console.error("Failed to fetch stacks data", error);
    }
}

export async function fetchRequirement(token) {
    const apiRequest = useApiRequest;

    try {
        const response = await apiRequest(token, "/job_related/requirements");
        return response.data;
    } catch (error) {
        console.error("Failed to fetch requirements data", error);
    }
}

export async function fetchResponsibility(token) {
    const apiRequest = useApiRequest;

    try {
        const response = await apiRequest(
            token,
            "/job_related/responsibilities",
        );
        return response.data;
    } catch (error) {
        console.error("Failed to fetch responsibilities data", error);
    }
}

export function useFetchStacks(token) {
    const apiRequest = useApiRequest;
    const [stacks, setStacks] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(token, "/job_related/stacks");
                setStacks(response.data);
            } catch (error) {
                console.error("Failed to fetch stacks data", error);
            }
        };

        fetchData();
    }, [token]);

    return stacks;
}

export function useFetchRecruitmentDetail(token, recId, updateTrigger, templateData = {statuses: [], user: {}, role: ""}) {
    const apiRequest = useApiRequest;
    const [ recruitment, setRecruitment ] = useState(null);

    useEffect(() => {
        if (recId) {
            if (recId != "new") {
                const fetchData = async () => {
                    try {
                        let t = {}
                        const response = await apiRequest(
                            token,
                            `/job_related/recruitment/${recId}`,
                        );
                        const proc = await apiRequest(
                            token,
                            `/job_related/processing?recruitment=${recId}`,
                        )
                        t = response.data;
                        t.processings = proc.data.map((p) => { return {
                            ...p,
                            name: `Процессинг кандидата ${p.employee?.name} на позицию ${p.vacancy?.title}`
                        }});
                        t.name = `${t.recruiter.name} - Найм кандидата ${t.employee?.name}`
                        setRecruitment(t);
                    } catch (error) {
                        console.error("Failed to fetch recruitment data", error);
                    }
                };

                fetchData();
            } else {
                setRecruitment({
                    id: null,
                    recruiter: templateData.user.partner,
                    status: templateData.statuses.find((value) => value.name.toLowerCase() === "Новый")
                });
            }
        } else { setRecruitment(null) }
    }, [recId, updateTrigger]);

    return [ recruitment, setRecruitment ];
}

export function useFetchRateTypes(token) {
    const apiRequest = useApiRequest;
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(
                    token,
                    "/job_related/rate_types",
                );
                setTypes(response.data);
            } catch (error) {
                console.error("Failed to fetch rate_types data", error);
            }
        };

        fetchData();
    }, [token]);

    return types;
}; 

export function useFetchRoles(token, updateTrigger) {
    const apiRequest = useApiRequest;
    const [ roles, setRoles ] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(
                    token,
                    "/users/roles",
                );
                setRoles(response.data);
            } catch (error) {
                console.error("Failed to fetch roles data", error);
            }
        };

        fetchData();
    }, [token, updateTrigger]);

    return roles;

}

export async function fetchRoles(token) {
    const apiRequest = useApiRequest;

    try {
        const response = await apiRequest(
            token,
            "/users/roles",
        );
        return response.data;
    } catch (error) {
        console.error("Failed to fetch roles data", error);
    }
}
